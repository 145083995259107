import React from "react";

import { ReactComponent as LogoSVG } from "../../svg/logo_header.svg";
import { ReactComponent as PhoneSVG } from "../../svg/phone.svg";
import appStore from "../../Store/MainStore";

const Header = () => {
  return (
    <>
    <div className="main__header container">
      <div className="container main__header-wrapper">
       {appStore.isAdmin ? <LogoSVG
          className="main__header-logo"
          onClick={() =>
            (window.location.href = `/`)
          } 
        /> : <LogoSVG
          className="main__header-logo"
          onClick={() =>
            (window.location.href = `/customers/${appStore.customerId}`)
          }
        /> }
        <div className="main__header-contact">
          <div className="main__header-contact-phone">
            <a href="tel:+74956486941">+7 (495) 648 69 41</a>
          </div>
          <div className="main__header-contact-email">
            <a href="mailto:hello@nativno.com">hello@nativno.com</a>
          </div>
          <div className="main__header-contact-phone-image">
            <a href="tel:+74956486941">
              <PhoneSVG />
            </a>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
