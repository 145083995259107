import React, { useEffect, useRef, useState } from "react";
import { prettyNumber } from "../../utils/Formatter";

import { observer } from "mobx-react-lite";
import appStore from "../../Store/MainStore";

const ProgressKPI = observer(() => {
  const [progressData, setProgressData] = useState({
    blue: 0,
    green: 0,
    pointsShow: false,
    textTopPercent: 0,
  });

  const percent = Math.round(appStore.progresskpi.realCoverage/appStore.progresskpi.kpi*100)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      // console.log(
      //   'CommonStatisticsStore.progressKPI.percentCoverage',
      //   CommonStatisticsStore.progressKPI.percentCoverage
      // );
      console.log("PROGRESSKPI:", appStore.progresskpi);
      const whitePercent =
        appStore.progresskpi.percentCoverage >= 100
          ? 100
          : appStore.progresskpi.percentCoverage;
      const bluePercent = 100 - whitePercent;
      setProgressData({
        blue: appStore.progresskpi.blueLineLength,
        green: appStore.progresskpi.greenLineLength,
        pointsShow: true,
        textTopPercent: whitePercent,
      });
    }, 300);
    return () => clearTimeout(timer);
  }, [
    appStore.progresskpi.blueLineLength,
    appStore.progresskpi.greenLineLength,
  ]);

  return (
    <>
      <div className="progreskpi">
        <span className="progreskpi__realcoverage">{prettyNumber(appStore.progresskpi.realCoverage)} ({percent}%)</span>
        <div
          className="progreskpi__accomplishment-green"
          style={{
            width: 100 + "%", //width: progressData.green + "%"
          }}
        ></div>
        <div
          className="progreskpi__accomplishment"
          style={{
            width: progressData.blue + "%",
          }}
        ></div>
        <div className="progreskpi__coverage progress__coverage-blue"></div>
        <div
            className={`progress__coverage-wrap ${
              progressData.pointsShow ? 'progress__coverage-wrap_show' : ''
            }`}
            style={{ width: progressData.textTopPercent + '%' }}
          >
            {/* <div
              className='progress__coverage-value'
              style={{ width: document.body.clientWidth - 30 }}
            >
              {prettyNumber(appStore.progresskpi.realCoverage)}
            </div> */}
          </div>
        {/* <div
          className='progress__coverage'
          style={{ ...progressData.textStyle }}
        >
          {prettyNumber({ value: CommonStatisticsStore.statistics.coverage })}
        </div> */}
        {/* <div
          className={`progreskpi__coverage-wrap ${
            progressData.pointsShow ? "progreskpi__coverage-wrap_show" : ""
          }`}
          style={{ width: progressData.textTopPercent + "%" }}
        >
          <div
            className="progreskpi__coverage-value"
            style={{ width: document.body.clientWidth - 30 }}
          >
            
            {prettyNumber({
              value: appStore.statistics.coverage,
            })}
          </div>
        </div> */}
      </div>
      <div className="progreskpi__scale">
        <div className="progreskpi__scale-count">
          <div className="progreskpi__percent-line"></div>
          <div className="progreskpi__percent-null">0</div>
        </div>
        {appStore.progresskpi.percentCoverage >= 100 && (
          <>
            <div className="kpi__plan" style={{
                  left: appStore.progresskpi.blueLineLength + '%',
                  top: -87 + 'px',
                }}>План KPI</div>
            <div className="progreskpi__scale-count progreskpi__scale-count_middle" style={{
                  left: appStore.progresskpi.blueLineLength + '%',
                }}>
              <div className="progreskpi__percent-count">
                <div className="progreskpi__percent-line"></div>
                <div className="progreskpi__percent-kpi"
                 >
                  {prettyNumber(appStore.progresskpi.kpi)}
                </div>
              </div>
            </div>
          </>
         )}
            {appStore.progresskpi.percentCoverage <= 100 &&<div className="kpi">KPI</div>}
            <div className="progreskpi__scale-count progreskpi__scale-count_last">
              <div className="progreskpi__percent-count">
                <div className="progreskpi__percent-line"></div>
                <div className="progreskpi__percent-kpi">
                  {appStore.progresskpi.percentCoverage <= 100 ? prettyNumber(appStore.progresskpi.kpi):prettyNumber(appStore.progresskpi.realCoverage)}
                </div>
              </div>
            </div>
         
      </div>
    </>
  );
});

export { ProgressKPI };

{
  /* {appStore.progresskpi.points.map((point) => (
            <div
              key={`progreskpi-kpi-${point}`}
              className={`progreskpi__percent ${
                point === 1 ? 'progreskpi__percent_kpi' : ''
              }`}
            >
              <div className='progreskpi__percent-line'></div>
            
              {point === 10 ? (
                <div className='progreskpi__percent-count'>
                  <div className="kpi">
                  KPI
                  </div>
                  <div className='progreskpi__percent-kpi'>
                    {prettyNumber( appStore.progresskpi.kpi,
                    )}
                  </div>
                </div>
              ) : (
                <div className='progreskpi__percent-count'>{point}</div>
              )}
            </div>
          ))} */
}

{
  /* {appStore.progresskpi.percentCoverage <= 300 && (
        <div
          className={`progreskpi__scale ${
            progressData.pointsShow ? 'progreskpi__scale_show' : ''
          }`}
        >
      



        </div>
      )} */
}

{
  /* {appStore.progresskpi.percentCoverage > 300 && (
        <div
          className={`progreskpi__scale ${
            progressData.pointsShow ? 'progreskpi__scale_show' : ''
          }`}
        >
          <div className={'progreskpi__percent'}>
            <div className='progreskpi__percent-line'></div>
            <div className='progreskpi__percent-count'>{0}</div>
          </div>
          <div className={'progreskpi__percent'}>
            <div className='progreskpi__percent-line'></div>
            <div className='progreskpi__percent-count'>
              {prettyNumber(appStore.progresskpi.percentCoverage,
              )}
              %
            </div>
          </div>
        </div>
      )} */
}

// {appStore.progresskpi.percentCoverage >= 100 && (
//   <>
// <div className="progreskpi__scale">
//   <div className="progreskpi__scale-count">
//     <div className="progreskpi__percent-line"></div>
//     <div>0</div>
//   </div>
//   <div className="kpi">KPI</div>
//   <div className="progreskpi__scale-count">
//     <div className="progreskpi__percent-count">
//       <div className="progreskpi__percent-line"></div>
//       <div className="progreskpi__percent-kpi">
//         {prettyNumber(appStore.progresskpi.kpi)}

//       </div>
//     </div>
//   </div>
// </div>
//   </>
// )}

//  {appStore.progresskpi.percentCoverage <= 100 && (
//   <>
//  <div>Lot of 100% new scale</div>
//   </>
// )}
