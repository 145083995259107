

export const calcCommonStat = (verticals, includeIds) => {
  let _commonStat = {
    coverageCount: 0,
    publicCount: 0,
    postCount: 0,
    creativeCount: 0,
    likeCount: 0,
    shareCount: 0,
    commentCount: 0,
    erCount: 0,
  };

  let verticalCount = 0;
  verticals.forEach((vertical) => {
    if (includeIds.includes(vertical.id)) {
      verticalCount++;
      _commonStat = {
        coverageCount: _commonStat.coverageCount + vertical.coverageCount,
        publicCount: _commonStat.publicCount + vertical.publicCount,
        postCount: _commonStat.postCount + vertical.postCount,
        creativeCount: _commonStat.creativeCount + vertical.creativeCount,
        likeCount: _commonStat.likeCount + (vertical.likeCount || 0),
        shareCount: _commonStat.shareCount + (vertical.shareCount || 0),
        commentCount: _commonStat.commentCount + (vertical.commentCount || 0),
        erCount: _commonStat.erCount + (vertical.erCount || 0),
      };
    }
  });

  _commonStat.erCount = +verticalCount ? (_commonStat.erCount / verticalCount) * 100 : 0;

  return _commonStat;
}

export const calcLegend = (verticals, _commonStat, includeIds) => {
  let _legend = [];

  verticals.forEach((vertical) => {
    if (includeIds.includes(vertical.id)) {
      _legend.push({
        id: vertical.id,
        title: vertical.title,
        coverageCount: vertical.coverageCount,
        coveragePercent:
          (vertical.coverageCount / _commonStat.coverageCount) * 100,
        color: vertical.color,
        verticalType: vertical.type
      });
    }
  });

  if (_legend.length > 0) {
    return _legend.sort((a, b) =>
      a.coverageCount < b.coverageCount
        ? 1
        : b.coverageCount < a.coverageCount
          ? -1
          : 0
    );
  }
  return _legend;
}

export const calcPie = (_legend, _commonStat) => {
  const _pie = [];
  let accAngle = 0;
  let prevLength = 0;
  let height = 7.5; //_legend.filter(v => v.coverageCount).length + 1;
  const minHeight = 1;
  let prevData = null;
  const _realLegend = _legend.filter((v) => v.coveragePercent);
  const countSectors = _realLegend.length || 1;
  const delta = countSectors < 2 ? 2 : (height - minHeight) / countSectors;
  _realLegend.forEach((vertical, num) => {
    accAngle += prevLength;
    height = prevData === vertical.coverageCount ? height : height - delta;
    prevLength =
      Math.PI * 2 * (vertical.coverageCount / _commonStat.coverageCount);
    prevData = vertical.coverageCount;
    _pie.push({
      id: vertical.id,
      color: vertical.color,
      coverage: vertical.coverageCount,
      coveragePercent:
        (vertical.coverageCount / _commonStat.coverageCount) * 100,
      num,
      label: { num: num },
      startAngle: accAngle,
      lengthSector: accAngle + prevLength,
      endLengthSector: prevLength,
      height,
    });
  });
  return _pie;
}