import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import appStore from "../../Store/MainStore";
import AuditoryBlock from "../Auditory/AuditoryBlock";
import ContentTop from "../ContentTop/ContentTop";
import SNExamplesBlock from "../Examples/SNExamplesBlock";
import DescriptionBlock from "../InfoBlock/Description";
import InfoBlock from "../InfoBlock/InfoBlock";
import SliderSource from "../Sources/SliderSource";
import SNStatsBlock from "../SNStats/SNStatsBlock";
import SourcesBlock from "../Sources/SourcesBlock";
import TopCreativesBlock from "../TopCreatives/TopCreativesBlock";
import ContentTopDetail from "../ContentTop/ContentTopDetail";
import RetargetBlock from "../Retarget/RetargetBlock";




const VerticalPage = () => {
  const param = useParams();
  const navigate = useNavigate();

  const fetch = useCallback(async (id) => {
    if (!Number(id)) {
      // navigate('/404');
    }
    const res = await appStore.getVerticalById(id);
    // if (res && res.commonStats && res.commonStats.coverageCount) {
    //   // navigate('/');
    // } else if (res && res === 401) {
    //   navigate('/login');
    // } else if (res && res === 404) {
    //   navigate('/404');
    // }
    console.log('третья', res)
  }, []);

  useEffect(() => {
    if (param && param.id) {
      fetch(param.id);
    };
    document.body.style.cursor = 'auto';
  }, [param]);

  return (
    <div>
      <ContentTopDetail verticalId={param&&param.id ? param.id : 0} />

      <div className="container-all"><SourcesBlock /></div>
      <div className="container-all"><AuditoryBlock /></div>
      <div className="container-all"><SNStatsBlock /></div>
      <div className="container-all"><TopCreativesBlock /></div>
      <div className="container-all"><RetargetBlock /></div>
      <div className="container-all"><SNExamplesBlock /></div>

    </div>
  );
}

export default VerticalPage;