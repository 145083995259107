import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import appStore from "../../Store/MainStore";
import CardBordered from "../Card/CardBordered";

import { ReactComponent as ArrowSVG } from '../../svg/slider-arrow.svg'
import Retarget from "./Retarget";
import useWindowDimensions from "../../utils/Hooks";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__next ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__prev ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const settings = {
  infinite: false,
  swipeToSlide: true,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};



const SliderRetarget = () => {
  const [tabSlider, setTabSlider] = useState (0);
    console.log('tab', tabSlider)
 
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTabSlider((prevSlide) => (prevSlide + 1) % 2);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, [tabSlider]); 

  const { isPhone, isBigPhone } = useWindowDimensions();
  return (
    <div>
      <Slider  {...settings} tabSlider={tabSlider} slidesToShow={isPhone&&!isBigPhone ? 1 : appStore.detailPage.target.length > 2 ? 2 : appStore.detailPage.target.length} dots={true}>
        { appStore.detailPage.target.socialNetworks.map(item => <Retarget key={`example-swipe-link-${item.shortName}`} item={item} />)}
      </Slider>
    </div>
  );
  
}
export default observer(SliderRetarget);