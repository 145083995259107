import React, { useState } from "react";

const CreativeThumb = ({
  image,
  i,
  creativeNum,
  onClick = () => {},
}) => {
  const [creativeThumb, setCreativeThumb] = useState(image || '/emptyimage/creatives.png');

  return (
    <div 
      onClick={() => onClick(i)} 
      className={`card-bordered__creatives-thumbs-item ${i === creativeNum ? 'card-bordered__creatives-thumbs-item_active' : ''}`}
    >
      <img src={creativeThumb} alt={`Креатив #${creativeNum}`} onError={() => setCreativeThumb('/emptyimage/creatives.png')} />
    </div>
  )
};

export default CreativeThumb;