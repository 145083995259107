import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import InfoBlock from "../InfoBlock/InfoBlock";
import SliderCreatives from "./SliderCreatives";

const TopCreativesBlock = () => {

  const { isPhone } = useWindowDimensions();

  if (appStore.detailPage.creatives && appStore.detailPage.creatives.length) {
    return <InfoBlock title={`${isPhone ? 'ТОП креативов' : 'ТОП-5 креативов'}`}>
      <div className={`${appStore.detailPage.creatives.length > 2 ? 'container-all' : 'container-all'}`}>
        <SliderCreatives />
      </div>
    </InfoBlock>;
  }

  return null;
};

export default observer(TopCreativesBlock);