import React from "react";
import { prettyNumber } from "../../utils/Formatter";

const ParameterText = ({
  value,
  text,
  isBig = false,
  className = '',
}) => {
  return (
    <div className={`parameter-text ${className}`}>
      <div className={`parameter-text__value ${isBig ? 'parameter-text__value-big' : ''}`}>{prettyNumber(value)}</div>
      <div className={`parameter-text__text ${isBig ? 'parameter-text__text-big' : ''}`}>{text}</div>
    </div>
  );
}

export default ParameterText;