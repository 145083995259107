import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./Components/Header/Header";
import AuthPage from "./Components/Pages/Auth";
import CommonStatsPage from "./Components/Pages/CommonStats";
import NotFoundPage from "./Components/Pages/NotFound";
import VerticalPage from "./Components/Pages/Vertical";
import { ToastContainer } from "react-toastify";
import LoaderContainer from "./Components/Loader/LoaderContainer";
import ScrollToTop from "./Components/ScrollTop/ScrollTop";

import "./styles/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AdminPage from "./Components/Pages/AdminPage";
import CustomersPage from "./Components/Pages/CustomersPage";

const App = () => {
  return (
    <div className="main">
      <Header />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/customers" element={<AdminPage />} />
          <Route path="/customers/:id" element={<CustomersPage/>} />
          <Route path="/campaigns" element={<CommonStatsPage />} />
          <Route path="/campaigns/:id" element={<VerticalPage />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <ToastContainer />
      <LoaderContainer />
    </div>
  );
};

export default App;
