import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import InfoBlock from "../InfoBlock/InfoBlock";
import SliderSource from "./SliderSource";

const SourcesBlock = () => {

  if (appStore.detailPage.sources && appStore.detailPage.sources.length) {
    return <InfoBlock title="Источники">
      <div className={`${appStore.detailPage.sources.length > 2 ? '' : ''}`}>
        <SliderSource />
      </div>
    </InfoBlock>;
  }

  return null;
};

export default observer(SourcesBlock);