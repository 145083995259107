import React from "react";
import CardBordered from "../Card/CardBordered";
import { ReactComponent as ExternalLinkSVG } from "../../svg/external-link.svg";
import { ReactComponent as Eye } from "../../svg/eye_retarget.svg";
import { prettyNumber, prettyNumberPostfix } from "../../utils/Formatter";

import { ReactComponent as EyeSVG } from "../../svg/eye_target.svg";
import { ReactComponent as TvSVG } from "../../svg/tv_target.svg";
import { ReactComponent as CreativesSVG } from "../../svg/creatives_target.svg";
import { ReactComponent as CursorSVG } from "../../svg/cursor_target.svg";
import { ReactComponent as ClickSVG } from "../../svg/click_target.svg";

import useWindowDimensions from "../../utils/Hooks";

const Params = ({ type, value, postFix }) => {
  switch (type) {
    case "eye":
      return (
        <div>
          <EyeSVG />
          {prettyNumber(value)}
        </div>
      );
    case "tv":
      return (
        <div>
          <TvSVG />
          {prettyNumber(value)}
        </div>
      );
    case "creatives":
      return (
        <div>
          <CreativesSVG />
          {prettyNumber(value)}
        </div>
      );
    case "cursor":
      return (
        <div>
          <CursorSVG />
          {prettyNumber(value)}
        </div>
      );
    case "click":
      return (
        <div>
          <ClickSVG />
          {prettyNumber(value)}%
        </div>
      );

    default:
      return <></>;
  }
};

const AdditionalTarget = ({ item }) => {
  const {isPhone}= useWindowDimensions();
  return (
    <>
      <CardBordered className={`card-bordered__${item.shortName}`}>
        <div className="">
          <div className="card-bordered__header-target ">
            {isPhone ? 
            <><div className="card-bordered__header-top">
                <div className="card-bordered__header-social-logo">
                  <img src={`/social/${item.shortName}.svg`} alt={item.shortName} />
                </div>
                <div className="card-bordered__title card-bordered__title-flex">
                  {item.title
                    .split("")
                    .map((v, index) => v === " " ? (
                      <div key={`${item.id}-${index}-example`}>&nbsp;</div>
                    ) : (
                      <div key={`${item.id}-${index}-example`}>{v}</div>
                    )
                    )}
                </div>
              </div><div className="card-bordered__header-indicators">
                  <Params
                    type="eye"
                    value={item.stats.coverage} />
                  <Params
                    type="tv"
                    value={item.stats.shows} />
                  <Params
                    type="creatives"
                    value={item.stats.creatives} />
                  <Params
                    type="cursor"
                    value={item.stats.clicks} />
                  <Params
                    type="click"
                    value={item.stats.ctr}
                    postFix="%" />
                </div></>
                : 
                <><div className="card-bordered__header-social-logo">
                <img src={`/social/${item.shortName}.svg`} alt={item.shortName} />
              </div><div className="card-bordered__header-toptarget">
                  <div className="card-bordered__title card-bordered__title-flex">
                    {item.title
                      .split("")
                      .map((v, index) => v === " " ? (
                        <div key={`${item.id}-${index}-example`}>&nbsp;</div>
                      ) : (
                        <div key={`${item.id}-${index}-example`}>{v}</div>
                      )
                      )}
                  </div>
                  <div className="card-bordered__header-indicators">
                    <Params
                      type="eye"
                      value={item.stats.coverage} />
                    <Params
                      type="tv"
                      value={item.stats.shows} />
                    <Params
                      type="creatives"
                      value={item.stats.creatives} />
                    <Params
                      type="cursor"
                      value={item.stats.clicks} />
                    <Params
                      type="click"
                      value={item.stats.ctr}
                      postFix="%" />
                  </div>
                </div></>
            }
           
          </div>
          <div className="card-bordered__link-container">
            {item.examples.map((v, i) => (
              <div
                className="card-bordered__example-link card-bordered__link-column "
                key={`example-${item.shortName}-${v.url}-${i}`}
                onClick={() =>
                  window.open(v.url, "_blank", "noopener,noreferrer")
                }
              >
                <div>Пример #{i + 1}</div>
                <ExternalLinkSVG />
              </div>
            ))}
          </div>
        </div>
      </CardBordered>
    </>
  );
};

export default AdditionalTarget;
