import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import CardBordered from "../Card/CardBordered";
import AuditoryBar from "../Diagrams/AuditoryBar";
import StatsBar from "../Diagrams/StatsBar";

const StatsDiagram = ({ title, data, cnPostfix }) => {
  const { isPhone } = useWindowDimensions();
  return (
    <CardBordered className={`card-bordered__snstats`}>
      <div className="card-bordered__dbl-title">
        <div className="card-bordered__title">
          { isPhone ? 'Динамика охвата' : 'Динамика набора охвата' }
        </div>
        <div className="card-bordered__dbl-title-legend">
          <div className="card-bordered__dbl-title-legend-f"><div className="card-bordered__dbl-title-legend-fblock"></div>Охват</div>
          <div className="card-bordered__dbl-title-legend-s"><div className="card-bordered__dbl-title-legend-sblock"></div>Посты</div>
        </div>
      </div>
      <StatsBar dataDiagram={appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].snDiagram} />
    </CardBordered>
  );
}

export default observer(StatsDiagram);