import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import ParameterIcon from "../Parameter/ParameterIcon";
import ParameterText from "../Parameter/ParameterText";

const TopCommonStat = () => {

  return (
    <>
     {/* <img className="" src={appStore.customerLogo}></img> */}
      <div className="top-content__top">
     
        <ParameterText value={appStore.commonStats.publicCount} text="Площадок" />
        <ParameterText value={appStore.commonStats.postCount} text="Размещений" />
        <ParameterText value={appStore.commonStats.creativeCount} text="Креативов" />
      </div>
     
      <div className="top-content__line"></div>
      <ParameterText value={appStore.commonStats.coverageCount} text="Текущий охват" isBig />
      <div className="top-content__line"></div>
      <div className="top-content__bottom">
    
        {appStore.commonStats.likeCount !== null ? <ParameterIcon key={`top-stat-like`} param={{ value: appStore.commonStats.likeCount, icon: 'likes', size: 'big' }} /> : null}
        {appStore.commonStats.shareCount !== null ? <ParameterIcon key={`top-stat-share`} param={{ value: appStore.commonStats.shareCount, icon: 'share', size: 'big' }} /> : null}
        {appStore.commonStats.commentCount !== null ? <ParameterIcon key={`top-stat-comment`} param={{ value: appStore.commonStats.commentCount, icon: 'comments', size: 'big' }} /> : null}
        {appStore.commonStats.erCount !== null ? <ParameterIcon key={`top-stat-er`} param={{ value: appStore.commonStats.erCount > 10 ? appStore.commonStats.erCount / 100 : appStore.commonStats.erCount, icon: 'er', size: 'big' }} /> : null}
      </div>    
    </>
  );
}

export default observer(TopCommonStat);