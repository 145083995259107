/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import appStore from "../../Store/MainStore";
import CardBordered from "../Card/CardBordered";

const LoginField = ({ _loginError = '', onChange, onSubmit }) => {
  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState(_loginError);
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    if (isFirst) {
      setLoginError('');
    } else {
      if (login === undefined) {
        setLoginError('');
      } else if (!login.trim()) {
        setLoginError('Поле обязательно для заполнения');
      } else {
        setLoginError('');
      }
    }
  }, [login, isFirst]);

  useEffect(() => {
    if (_loginError) {
      setLoginError(_loginError);
    }
  }, [_loginError]);

  return (
    <div className="login-card__row">
      <input name="vert-login" className={`login-card__input ${loginError ? 'login-card__input_error' : ''}`} type="text" placeholder="Логин..." value={login} onChange={(e) => { setLogin(e.target.value); setIsFirst(false); }} autoComplete="off" onBlur={() => onChange(login)} onKeyDown={(e) => { if (e.key === 'Enter') {onSubmit(login);}}} />
      {loginError && <div className="login-card__input-text-error">{loginError}</div>}
    </div>
  )
}

const PasswordField = ({ _passError = '', onChange, onSubmit }) => {
  const [password, setPassword] = useState('');
  const [passError, setPassError] = useState(_passError);
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    if (isFirst) {
      setPassError('');
    } else {
      if (password === undefined) {
        setPassError('');
      } else if (!password.trim()) {
        setPassError('Поле обязательно для заполнения');
      } else {
        setPassError('');
      }
    }
  }, [password, isFirst]);

  useEffect(() => {
    if (_passError) {
      setPassError(_passError);
    }
  }, [_passError]);

  return (
    <div className="login-card__row">
      <input name="vert-password" className={`login-card__input ${passError ? 'login-card__input_error' : ''}`} type="password" placeholder="Пароль..." value={password} onChange={(e) => { setPassword(e.target.value); setIsFirst(false); }} autoComplete="off" onBlur={() => onChange(password)} onKeyDown={(e) => { if (e.key === 'Enter') {onSubmit(password);}}} />
      {(passError) && <div className="login-card__input-text-error">{passError }</div>}
    </div>
  )
}

const AuthPage = () => {
  console.log('UPDATE!!!')
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [passError, setPassError] = useState('');

  const navigate = useNavigate();

  const fetch = useCallback(async ({login, password}) => {
    if (!login.trim()) {
      setLoginError('Поле обязательно для заполнения');
    }
    if (!password.trim()) {
      setPassError('Поле обязательно для заполнения');
    }
    if(login.trim() && password.trim() && !appStore.isLoading) {
      // appStore.setToken(login, password);
      // fetch();
      const response = await appStore.postAuthentication(login, password,);
      if (response.status === 401) {
        toast('Auth error')
      }
      if (response.status === 200 && response.isAdmin) {
        // appStore.getCustomersData()
        navigate(`/customers`)
        console.log("as admin")
      } else if (response.status ===200 && !response.isAdmin) {
        // appStore.getVerticals()
        navigate(`/customers/${response.user}`)
        console.log('as user')
      } else if ( response.status === 401) {
        setLoginError('Проверьте правильность заполнения');
        setPassError('Проверьте правильность заполнения');
      }
      console.log('gytyftft', response.user)
      // const res = await appStore.getVerticals();
      // if (res && res.commonStats && res.commonStats.coverageCount) {
      //   navigate('/');
      // } else if (res && res === 401) {
      //   setLoginError('Проверьте правильность заполнения');
      //   setPassError('Проверьте правильность заполнения');
      // }
    }
    },
  []);


  useEffect(() => {
    appStore.isLoading = false;
  }, []);

  return (
    <div className="login">
      <CardBordered className="login-card">
        <div className="login-card__title">Авторизация</div>
          <div className="login-card__subtitle">Введите пароль, чтобы войти.</div>
          <LoginField onChange={(val) => setLogin(val)} onSubmit={(_login) => fetch({login: _login, password})} _loginError={loginError} />
          <PasswordField onChange={(val) => setPassword(val)} onSubmit={(_password) => fetch({login, password: _password})} _passError={passError} />
          <div 
            onClick={()=>{
              fetch({login, password});
            }}
            className="login-card__row">
              <input className="login-card__btn" type="button" value="Войти" />
          </div>
      </CardBordered>
    </div>
  )
}

export default AuthPage;