import React from "react";
import CardBordered from "../Card/CardBordered";
import { ReactComponent as ExternalLinkSVG } from "../../svg/external-link.svg";
import { ReactComponent as Eye } from '../../svg/eye_retarget.svg';
import { ReactComponent as EyeMob } from '../../svg/eye_retarget-mob.svg';
import useWindowDimensions from "../../utils/Hooks";
import { prettyNumber, prettyNumberPostfix } from "../../utils/Formatter";

const Retarget = ({ item }) => {
  const {isPhone}= useWindowDimensions();

  return (
    <>
      <CardBordered className={`card-bordered__${item.shortName}`}>
        <div className="">
          <div className="card-bordered__header">

            {isPhone ? <>
            <div className="top">
              <div className="card-bordered__header-social-logo">
                <img
                  src={`/social/${item.shortName}.svg`}
                  alt={item.shortName} />
              </div>
            </div><div className="topsecond">
            <div className="card-bordered__title card-bordered__title-flex">
                {item.title
                  .split("")
                  .map((v, index) =>
                    v === " " ? (
                      <div key={`${item.id}-${index}-example`}>&nbsp;</div>
                    ) : (
                      <div key={`${item.id}-${index}-example`}>{v}</div>
                    )
                  )}
              </div>

            <div className="card-bordered__header-top">
              <EyeMob></EyeMob>
              <div className="card-bordered__header-value">{prettyNumber(item.stats.coverage)}</div>
            </div>
              </div>
              </>
            :   <><div className="card-bordered__header-top">
                <div className="card-bordered__header-social-logo">
                  <img
                    src={`/social/${item.shortName}.svg`}
                    alt={item.shortName} />
                </div>
                <div className="card-bordered__title card-bordered__title-flex">
                  {item.title
                    .split("")
                    .map((v, index) => v === " " ? (
                      <div key={`${item.id}-${index}-example`}>&nbsp;</div>
                    ) : (
                      <div key={`${item.id}-${index}-example`}>{v}</div>
                    )
                    )}
                </div>
              </div><div className="card-bordered__header-top">
                  <Eye></Eye>
                  <div className="card-bordered__header-value">{prettyNumber(item.stats.coverage)}</div>
                </div></>}
          
          </div>
          <div className="card-bordered__link-container">
          {item.examples.map((v, i) => (
           
            <div
              className="card-bordered__example-link card-bordered__link-column "
              key={`example-${item.shortName}-${v.url}-${i}`}
              onClick={() =>
                window.open(v.url, "_blank", "noopener,noreferrer")
              }
            >
              <div>Пример #{i + 1}</div>
              <ExternalLinkSVG />
            </div>
           
          ))}
           </div>
        </div>
      </CardBordered>
    </>
  );
};

export default Retarget;
