import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Slider from "react-slick";
import appStore from "../../Store/MainStore";
import CardBordered from "../Card/CardBordered";

import { ReactComponent as ArrowSVG } from '../../svg/slider-arrow.svg'
import AdditionalTarget from "./AdditionaTarget";
import useWindowDimensions from "../../utils/Hooks";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__next ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__prev ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const settings = {
  infinite: false,
  swipeToSlide: true,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};



const SliderAdditionalTarget = () => {

  const { isPhone, isBigPhone } = useWindowDimensions();

  return (
    <div>
      <Slider {...settings} slidesToShow={isPhone&&!isBigPhone ? 1 : appStore.detailPage.retarget.length > 2 ? 2 : appStore.detailPage.retarget.length} dots={true}>
        { appStore.detailPage.retarget.socialNetworks.map(item => <AdditionalTarget key={`example-swipe-link-${item.shortName}`} item={item} />)}
      </Slider>
    </div>
  );
}
export default observer(SliderAdditionalTarget);