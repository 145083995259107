import React, { useState } from "react";

import { ReactComponent as ExternalLinkSVG } from '../../svg/external-link.svg'

const SourceSliderCard = ({ item }) => {
  const [image, setImage] = useState(item.image || '/emptyimage/source.png');

  return (<>
    <div className="card-bordered__sources-top">
      <div className="card-bordered__sources-site">{item.site}</div>
      <div className="card-bordered__sources-link" onClick={() => window.open(item.url, '_blank', 'noopener,noreferrer')}><ExternalLinkSVG /></div>
    </div>
    <div className="card-bordered__sources-info">
      <div className="card-bordered__sources-title">{item.title}</div>
      <div className="card-bordered__sources-description">{item.description}</div>
    </div>
    <div className="card-bordered__sources-image">
      <img src={image} alt={item.site} onError={() => setImage("/emptyimage/source.png")}/>
    </div>
  </>
  )
}

export default SourceSliderCard;