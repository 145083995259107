import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import InfoBlock from "../InfoBlock/InfoBlock";
import Retarget from "./Retarget";
import AdditionalTarget from "./AdditionaTarget";
import SliderRetarget from "./SliderRetarget";
import SliderAdditionalTarget from "./SliderAdditionalTarget";
// import SNExample from "./SNExample";
// import SliderExamples from "./SliderExample";

import { ReactComponent as VKSVG } from "../../svg/sn-vk.svg";
import { ReactComponent as OKSVG } from "../../svg/sn-ok.svg";
import { ReactComponent as TgSVG } from "../../svg/sn-tg.svg";
import { ReactComponent as YtSVG } from "../../svg/sn-yt.svg";
import { ReactComponent as FbSVG } from "../../svg/sn-fb.svg";
import { ReactComponent as ZenSVG } from "../../svg/sn-zen.svg";
import { ReactComponent as OverSVG } from "../../svg/sn-over.svg";
import { ReactComponent as InstaSVG } from "../../svg/sn-insta.svg";
import { ReactComponent as TiktokSVG } from "../../svg/sn-tiktok.svg";

const SNIcon = ({ shortName, className }) => {
  switch (shortName) {
    case "vk":
      return <VKSVG className={className} />;
    case "ok":
      return <OKSVG className={className} />;
    case "tg":
      return <TgSVG className={className} />;
    case "yt":
      return <YtSVG className={className} />;
    case "fb":
      return <FbSVG className={className} />;
    case "zen":
      return <ZenSVG className={className} />;
    case "over":
      return <OverSVG className={className} />;
    case "insta":
      return <InstaSVG className={className} />;
    case "tiktok":
      return <TiktokSVG className={className} />;

    default:
      return <></>;
  }
};

// const TabsComponent = observer(() => {
//   const { isPhone } = useWindowDimensions();

//   return (
//     <div className="infoblock__header-tab">
//       {appStore.detailPage.target.socialNetworks.map((item) => (
//         <div
//           key={`filter-${item.id}`}
//           className={`infoblock__header-tab-item ${
//             appStore.detailPage.target.activeTab === item.id
//               ? "infoblock__header-tab-item_active"
//               : ""
//           }`}
//           onClick={() => {
//             appStore.detailPage.target.activeTab = item.id;
//           }}
//         >
//           {isPhone ? (
//             <SNIcon
//               className={`infoblock__header-tab-item-icon ${
//                 appStore.detailPage.target.activeTab === item.id
//                   ? "infoblock__header-tab-item-icon_active"
//                   : ""
//               }`}
//               shortName={item.shortName}
//             />
//           ) : null}
//         </div>
//       ))}
//     </div>
//   );
  
// });

// const Header = () => {
//   return (
//     <div className="infoblock__header">
//       <div className="infoblock__header-tab">
//         <TabsComponent />
//       </div>
//     </div>
//   );
// };
const RetargetBlock = () => {
  const { isPhone } = useWindowDimensions();


  useEffect(() => {});

  if (appStore.detailPage.target?.isShow) {
    return (
      <div>
        <InfoBlock title="РЕТаргет">
          <div className="description-block__info-retarget">
            {appStore.detailPage.target.description}
          </div>
          {isPhone ? (
            <SliderRetarget />
          ) : (
            <div className="cards__example">
              {appStore.detailPage.target.socialNetworks.map((item) => (
                <Retarget key={`example-link-${item.shortName}`} item={item} />
              ))}
            </div>
          )}
        </InfoBlock>
        <InfoBlock title="дополнительный таргет">
          <div className="description-block__info-retarget">
            {appStore.detailPage.retarget.description}
          </div>
          {isPhone ? (
            <SliderAdditionalTarget/>
          ) : (
            <div className="cards__example">
              {appStore.detailPage.retarget.socialNetworks.map((item) => (
                <AdditionalTarget
                  key={`example-link-${item.shortName}`}
                  item={item}
                />
              ))}
            </div>
          )}
        </InfoBlock>
      </div>
      
    );
    
  }
  
  return <></>;
};

export default observer(RetargetBlock);

// const { isPhone } = useWindowDimensions();

// if (appStore.detailPage.examples && appStore.detailPage.examples.length) {
//   return <InfoBlock title="Примеры размещений">
//     {
//       isPhone ? <SliderExamples />
//       :
//       <div className="cards__example">
//         { appStore.detailPage.examples.map(item => <SNExample key={`example-link-${item.shortName}`} item={item} />)}
//       </div>
//     }
//   </InfoBlock>;
// }

// return null;

{
  /* <h1>Это блок дополнительного таргета</h1>
        <div>{appStore.detailPage.target.description}</div>
        <div>SocialNetwork</div>
        {appStore.detailPage.target.socialNetworks.map((snItem) => (
          <div key={snItem.shortName}>
            <div>=====</div>
            {snItem.title}
            <div>stats</div>
            <div>
              "coverage": {snItem.stats.coverage}, "shows": {snItem.stats.shows}
              , "creatives": {snItem.stats.creatives}, "clicks":{" "}
              {snItem.stats.clicks}, "ctr": {snItem.stats.ctr}
            </div>
            <div>Examples</div>
            {snItem.examples.map((snExample, index) => (
              <a
                key={snExample.url}
                href={snExample.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Пример #{index + 1}
              </a>
            ))}
          </div>
        ))} */
}
