import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSwipeable } from "react-swipeable";

import appStore from "../../Store/MainStore";
import { prettyNumber } from "../../utils/Formatter";

import { ReactComponent as ArrowSVG } from "../../svg/slider-arrow.svg";

const LegendMobile = () => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        appStore &&
        appStore.cmLegend &&
        appStore.cmLegend[0] &&
        appStore.cmLegend[0].id
      ) {
        appStore.handleCommonTip(true, appStore.cmLegend[0].id);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
      // appStore.handleCommonTip(false, 0);
    };
  }, []);

  const changeNum = useCallback(
    (delta) => {
      const tmpNum = selected + delta;
      if (tmpNum > -1 && tmpNum < appStore.cmLegend.length) {
        setSelected(tmpNum);
        appStore.handleCommonTip(true, appStore.cmLegend[tmpNum].id);
      }
    },
    [selected]
  );

  const navigate = useNavigate();

  const handlers = useSwipeable({
    onSwipedLeft: () => changeNum(1),
    onSwipedRight: () => changeNum(-1),
  });

  if (
    appStore.cmLegend &&
    appStore.cmLegend.length &&
    appStore.cmLegend[selected]
  ) {
    return (
      <div {...handlers} className="top-content__legends-mobile-item">
        <div
          onClick={(e) => {
            if (appStore.cmLegend[selected].coverageCount) {
              navigate(`/customers/${appStore.cmLegend[selected].id}`);
            } else {
              toast("Данные в процессе сбора");
            }
          }}
          className="top-content__legends-mobile-num"
          style={{ backgroundColor: appStore.cmLegend[selected].color }}
        >
          <div className="top-content__legends-mobile-inner">
            {selected + 1}
          </div>
        </div>
        <div
          onClick={() => {
            if (appStore.cmLegend[selected].coverageCount) {
              navigate(`/customers/${appStore.cmLegend[selected].id}`);
            } else {
              toast("Данные  в процессе сбора");
            }
          }}
          className="top-content__legends-mobile-info"
        >
          <div className="top-content__legends-mobile-name">
            {appStore.cmLegend[selected].title}
          </div>
          <div className="top-content__legends-mobile-value">
            Охват: {prettyNumber(appStore.cmLegend[selected].coverageCount)} (
            {prettyNumber(appStore.cmLegend[selected].coveragePercent)}%)
          </div>
        </div>
        <div className="top-content__legends-mobile-controls">
          <div
            onClick={() => changeNum(-1)}
            className="top-content__legends-mobile-controls-prev"
          >
            <ArrowSVG />
          </div>
          <div
            onClick={() => changeNum(1)}
            className="top-content__legends-mobile-controls-next"
          >
            <ArrowSVG />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default observer(LegendMobile);
