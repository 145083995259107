import React from "react";

import { ReactComponent as LikesSVG } from '../../svg/likes.svg'
import { ReactComponent as CommentsSVG } from '../../svg/comments.svg'
import { ReactComponent as ShareSVG } from '../../svg/share.svg'
import { ReactComponent as ERSVG } from '../../svg/ER.svg'
import { prettyNumber } from "../../utils/Formatter";

const Icon = ({icon, className}) => {
  switch (icon) {
    case 'likes':
      return <LikesSVG className={className} />;
    case 'comments':
      return <CommentsSVG className={className}/>;
    case 'share':
      return <ShareSVG className={className}/>;
    case 'er':
      return <ERSVG className={className}/>;
  
    default:
      return <></>;
  }
}

const ParameterIcon = ({
  param
}) => {
  return (
    <div className="parameter-icon">
      <Icon className={`parameter-icon__icon-${param.size}`} icon={param.icon} />
      <div className={`parameter-icon__value parameter-icon__value-${param.size}`}>{prettyNumber(param.value)}{param.icon === 'er' ? '%' : ''}</div>
    </div>
  );
}

export default ParameterIcon;