/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';

import ContentTop from "../ContentTop/ContentTop";
import appStore from "../../Store/MainStore";
import InfoBlock from "../InfoBlock/InfoBlock";
import VerticalCard from '../Card/VerticalCard';

import { ReactComponent as ArrowSVG } from '../../svg/arrow.svg'
import VerticalCards from "../Card/VerticalCards";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/Hooks";
import Customers from "../Customers/Customers";


const sortItems = [
  { label: 'По охвату', name: 'coverageCount'},
  { label: 'По лайкам', name: 'likeCount'},
  { label: 'По репостам', name: 'shareCount'},
  { label: 'По комментариям', name: 'commentCount'},
  { label: 'По ER', name: 'erCount'},
]
const filterItems = [
  { label: 'Все', id: 3},
  { label: 'Постоянные', id: 1},
  { label: 'Ситуативные', id: 2},
]
const SortComponent = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const refSort = useRef(null);

  useEffect(() => {
    const onClick = e => {
      if (refSort && refSort.current && !refSort.current.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [isOpen, refSort]);

  return <div ref={refSort} className="infoblock__sort">
    <div className="infoblock__sort-selected" onClick={() => setIsOpen(!isOpen)}>{sortItems.filter(v => v.name === appStore.cmVerticalSort)[0].label} <ArrowSVG /></div>
    {isOpen &&<div className="infoblock__sort-items">
      {sortItems.map(item => (<div key={`sort-${item.name}`} className={`infoblock__sort-item ${appStore.cmVerticalSort === item.name ? 'infoblock__sort-item_active' : ''}`} onClick={() => {appStore.cmVerticalSort = item.name; setIsOpen(false); appStore.cmChangeFilterSort();}}>{item.label}</div>))}
    </div>}
    
  </div>
})

const FilterComponent = observer(() => {
  return (<div className="infoblock__header-tab">
    {filterItems.map(item => (<div key={`filter-${item.id}`} className={`infoblock__header-tab-item ${appStore.cmVerticalFilter === item.id ? 'infoblock__header-tab-item_active' : ''}`} onClick={() => { appStore.cmVerticalFilter = item.id; appStore.cmChangeFilterSort();}}>{item.label}</div>))}
  </div>)
})

const Header = () => {
  const { isPhone } = useWindowDimensions();

  return (<div className="infoblock__header">
    <div className="infoblock__header-sort">
      {isPhone ? null : <div className="infoblock__header-sort-title">Сортировать:</div>}
      <div><SortComponent /></div>
    </div>
    <div className="infoblock__header-tab">
      <FilterComponent />
    </div>
  </div>)
}

const CommonStatsPage = () => {

  const navigate = useNavigate();

  const fetch = useCallback(async () => {
    const res = await appStore.getVerticals();
    if (res && res.commonStats && res.commonStats.coverageCount) {
      // navigate('/');
      console.log('fffffffff', res)
    } else if (res && res === 401) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    fetch()
    document.body.style.cursor = 'auto';
  }, []);

  if (appStore.isLoading) {
    return null;
  }

 

  return (<>
    <ContentTop isLegend />
    <div className="container-all">
      <InfoBlock btnBack={appStore.isAdmin ? true : false} title="Кампании" header={<Header />} isTwoRow className="verticals-block">
        <VerticalCards />
      </InfoBlock>
    </div>
  </>);
}

export default observer(CommonStatsPage);