import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import useWindowDimensions from "../../utils/Hooks";

export default function LineCard({
  data,
  isProgressHide = false,
}) {
  const { isPhone } = useWindowDimensions();

  return (
    <ResponsiveContainer width={isPhone ? '100%' : 173} height={isPhone ? isProgressHide ? '100%' : '80%' : isProgressHide ? 117 : 82}>
      <LineChart
        width={isPhone ? '100%' : 173}
        height={isPhone ? isProgressHide ? '100%' : '80%' : isProgressHide ? 117 : 82}
        data={data}
        margin={0}
        padding={0}
      >
        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" strokeWidth={1} stroke={"#6A7084"}/>
        <XAxis dataKey="date" tick={false} height={1} stroke="#484F66" label={{ value: "охват", fill: '#6A7084', position: "insideBottomRight" }} />
        <YAxis tickCount={10} domain={['dataMin', 'dataMax']} width={1} stroke="#484F66"/>
        <Line
          type="monotone"
          dataKey="coverage"
          stroke="#FFFFFF"
          isAnimationActive={false}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
