import React from "react";
import { ReactComponent as ArrowSVG } from '../../svg/arrow_left.svg';
import { useNavigate,} from "react-router"


const InfoBlock = ({
  title = '',
  header = null,
  isTwoRow = false,
  className = '',
  btnBack = false,
  children
}) => {
  const navigator = useNavigate()

  return (<div className={`infoblock ${className}`}>
    <div className={`infoblock__header ${isTwoRow ? 'infoblock__header-two-row' : ''}`}>
    {btnBack ? <div onClick={() => navigator ('/customers')} className="description-block__comeback"><ArrowSVG className="description-block__arrow"></ArrowSVG></div> : null}
      <div className="infoblock__title">{title}</div>
      <div className="infoblock__title-action">{header}</div>
    </div>
    <div className="infoblock__line"></div>
    {children}
  </div>)
};

export default InfoBlock;