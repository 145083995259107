import { observer } from "mobx-react-lite";
import React from "react";
import Slider from "react-slick";
import appStore from "../../Store/MainStore";
import CardBordered from "../Card/CardBordered";
import { ReactComponent as ExternalLinkSVG } from '../../svg/external-link.svg'
import { ReactComponent as ArrowSVG } from '../../svg/slider-arrow.svg'
import CreativeCard from "./CreativeCard";
import useWindowDimensions from "../../utils/Hooks";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__next ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__prev ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const settings = {
  infinite: false,
  swipeToSlide: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};



const SliderCreatives = () => {
  const { isPhone, isBigPhone } = useWindowDimensions();

  return (
    <div>
      <Slider {...settings }  slidesToShow={isPhone&&!isBigPhone ? 1 : appStore.detailPage.creatives.length > 2 ? 2 : appStore.detailPage.creatives.length} dots={isPhone ? true : false}>
        {
          appStore.detailPage.creatives.map((creative) => (<CreativeCard key={`creative-card-${creative.id}-${appStore.detailPage.common.id}`} item={creative} />))
        }
      </Slider>
    </div>
  );
}
export default observer(SliderCreatives);