import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Slider from "react-slick";
import appStore from "../../Store/MainStore";
import CardBordered from "../Card/CardBordered";

import { ReactComponent as ArrowSVG } from '../../svg/slider-arrow.svg'
import SourceSliderCard from "./SourceSliderCard";
import useWindowDimensions from "../../utils/Hooks";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__next ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__prev ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const settings = {
  // className: "center",
  // centerMode: true,
  infinite: true,
  // slidesToShow: 2,
  swipeToSlide: true,
  // focusOnSelect: true,
  // variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};



const SliderSource = () => {
  const { isPhone, isBigPhone } = useWindowDimensions();

  return (
    <div>
      <Slider {...settings} slidesToShow={isPhone&&!isBigPhone ? 1 : appStore.detailPage.sources.length > 2 ? 2 : appStore.detailPage.sources.length} dots={isPhone} centerMode={false}>
      {/* <Slider {...settings} slidesToShow={isPhone&&!isBigPhone ? 1 : appStore.detailPage.sources.length > 2 ? 2 : appStore.detailPage.sources.length} dots={isPhone} centerMode={isPhone&&!isBigPhone}> */}
        {
          appStore.detailPage.sources.map((item, index) => (<CardBordered key={`sources-id-${index}`} className={`card-bordered__sources`} >
            <SourceSliderCard item={item} />
          </CardBordered>))
        }
      </Slider>
    </div>
  );
}
export default observer(SliderSource);