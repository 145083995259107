import { ReactComponent as ArrowSVG } from "../../svg/arrow_right_cust.svg";
// import { ReactComponent as ArrowMobSVG } from "../../svg/arr_mob.svg";
import { ReactComponent as ChatSVG } from "../../svg/chat_tb.svg";
import { ReactComponent as LikeSVG } from "../../svg/like_cust.svg";
import { ReactComponent as ErrSVG } from "../../svg/er_cust.svg";
import { ReactComponent as RepostSVG } from "../../svg/repost_cust.svg";
import appStore from "../../Store/MainStore";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { pluralWords } from "../../utils/Formatter";
// import React from 'react'
import ParameterIcon from "../Parameter/ParameterIcon";
import { prettyNumber, prettyNumberPostfix } from "../../utils/Formatter";
// import NoData from "../Card/NoData";
import { themeblocks } from "../../Store/mock";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
// import NoDataMob from "../Card/NoDataMob";

// eslint-disable-next-line no-empty-pattern
const Customers = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="themeblock__all">
        {appStore.themeBlock.map((themeBlocks, index) => (
          <div className="border" key={themeBlocks.id}>
            <div
              className="themeblock__body"
              onClick={async () => {
                if (themeBlocks.campaignsCount > 0) {
                  // appStore.getCustomerId(themeBlocks.id);
                  navigate(`/customers/${themeBlocks.id}`);
                } else {
                }
              }}
            >
              <div className="themeblock__item">
                {themeBlocks.coverage > 0 ? (
                  <div className="themeblock__footer-mob">
                    <div className="themeblock__data-item">
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          <ErrSVG className="themeblock__chat"></ErrSVG>
                        </div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.err)}%
                        </div>
                      </div>
                    </div>
                    <div className="themeblock__data-item">
                      <div className="themeblock__data-title">
                        <ChatSVG className="themeblock__chat"></ChatSVG>
                      </div>
                      <div className="themeblock__data-num">
                        {prettyNumber(themeBlocks.replies)}
                      </div>
                    </div>
                    <div className="themeblock__data-item">
                      <div className="themeblock__data-title">
                        <RepostSVG className="themeblock__chat"></RepostSVG>
                      </div>
                      <div className="themeblock__data-num">
                        {prettyNumber(themeBlocks.repost)}
                      </div>
                    </div>
                    <div className="themeblock__data-item">
                      <div className="themeblock__data-title">
                        <LikeSVG className="themeblock__like"></LikeSVG>
                      </div>
                      <div className="themeblock__data-num">
                        {prettyNumber(themeBlocks.reactions)}
                      </div>
                    </div>
                  </div>
                ) : null}
                <img className="themeblock__img" src={themeBlocks.image}></img>
                <div className="themeblock__item-mob">
                  {themeBlocks.coverage > 0 ? (
                    <div className="themeblock__data-mob">
                      <div className="themeblock__data-mob-flex">
                        <div className="themeblock__data-item">
                          <div className="themeblock__data-title">Охват</div>
                          <div
                            className="themeblock__data-num"
                            data-tip={
                              themeBlocks.coverage > 100000000
                                ? prettyNumber(themeBlocks.coverage)
                                : ""
                            }
                          >
                            {prettyNumber(themeBlocks.coverage)}
                          </div>
                        </div>
                        <div className="themeblock__data-item">
                          <div className="themeblock__data-title">
                            {pluralWords(themeBlocks.public_count, [
                              "Паблик",
                              "Паблика",
                              "Пабликов",
                            ])}
                          </div>
                          <div className="themeblock__data-num">
                            {prettyNumber(themeBlocks.public_count)}
                          </div>
                        </div>
                        <div className="themeblock__data-item">
                          <div className="themeblock__data-title">
                            Размещений
                          </div>
                          <div className="themeblock__data-num">
                            {prettyNumber(themeBlocks.post_count)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* (
                    <div className="themeblock__no-data-mob">
                      <NoDataMob></NoDataMob>{" "}
                    </div>
                  ) */}
                </div>

                <div className="themeblock__info">
                <img className="themeblock__img-mob" src={themeBlocks.image}></img>
                  <div className="themeblock__header">
                 
                    <div
                      className="themeblock__header-title"
                      data-tip={themeBlocks.name}
                    >
                      {themeBlocks.name}
                    </div>
                    {themeBlocks.campaignsCount > 0 ? (
                      <a className="themeblock__header-href">
                        {" "}
                        {pluralWords(themeBlocks.campaignsCount, [
                          "Кaмпания:",
                          "Кaмпании:",
                          "Кaмпаний:",
                        ])}{" "}
                        {themeBlocks.campaignsCount}
                        <ArrowSVG className="themeblock__arrow"></ArrowSVG>
                        {/* <ArrowMobSVG className="themeblock__arrow-mob"></ArrowMobSVG> */}
                      </a>
                    ) : null}
                  </div>
                  {themeBlocks.coverage > 0 ? (
                    <div className="themeblock__data">
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          Текущий охват
                        </div>
                        <div
                          className="themeblock__data-num"
                          data-tip={
                            themeBlocks.coverage > 100000000
                              ? prettyNumber(themeBlocks.coverage)
                              : ""
                          }
                        >
                          {prettyNumberPostfix(
                            themeBlocks.coverage,
                            0,
                            100000000
                          )}
                        </div>
                      </div>
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          {pluralWords(themeBlocks.public_count, [
                            "Паблик",
                            "Паблика",
                            "Пабликов",
                          ])}
                        </div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.public_count)}
                        </div>
                      </div>
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">Размещений</div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.post_count)}
                        </div>
                      </div>
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          <LikeSVG className="themeblock__like"></LikeSVG>
                        </div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.reactions)}
                        </div>
                      </div>
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          <RepostSVG className="themeblock__chat"></RepostSVG>
                        </div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.repost)}
                        </div>
                      </div>
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          <ChatSVG className="themeblock__chat"></ChatSVG>
                        </div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.replies)}
                        </div>
                      </div>
                      <div className="themeblock__data-item">
                        <div className="themeblock__data-title">
                          <ErrSVG className="themeblock__chat"></ErrSVG>
                        </div>
                        <div className="themeblock__data-num">
                          {prettyNumber(themeBlocks.err)}%
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <ReactTooltip></ReactTooltip>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default observer(Customers);
