import React, { useCallback, useState } from "react";
import CardBordered from "../Card/CardBordered";

import { ReactComponent as ChatSVG } from '../../svg/cr_chat.svg';
import { ReactComponent as ERSVG } from '../../svg/cr_er.svg';
import { ReactComponent as EyeSVG } from '../../svg/cr_eye.svg';
import { ReactComponent as LikesSVG } from '../../svg/cr_likes.svg';
import { ReactComponent as ShareSVG } from '../../svg/cr_share.svg';
import { ReactComponent as ArrowLeftSVG } from '../../svg/cr_arrow_left.svg';
import { ReactComponent as ArrowRightSVG } from '../../svg/cr_arrow_right.svg';
import { prettyNumber } from "../../utils/Formatter";
import CreativeThumb from "./CreativeThumb";
import useWindowDimensions from "../../utils/Hooks";

const Params = ({ type, value, postFix }) => {

  switch (type) {
    case 'coverage':
      return <div><EyeSVG />{prettyNumber(value)}</div>;
    case 'likes':
      return <div><LikesSVG />{prettyNumber(value)}</div>;
    case 'comments':
      return <div><ChatSVG />{prettyNumber(value)}</div>;
    case 'share':
      return <div><ShareSVG />{prettyNumber(value)}</div>;
    case 'er':
      return <div><ERSVG />{prettyNumber(value)}%</div>;
  
    default:
      return <></>;
  };
}

const CreativeCard = ({ item }) => {
  const { isPhone } = useWindowDimensions();

  const [creativeNum, setCreativeNum] = useState(0);
  const [creativeImage, setCreativeImage] = useState(item.creatives[creativeNum].image || '/emptyimage/creatives.png');

  const changeImage = useCallback((num) => {
    setCreativeNum(num);
    setCreativeImage(item.creatives[num].image)
  }, [item]);

  return (<CardBordered className="card-bordered card-bordered__creatives">
    <div className="card-bordered__creatives-title">
      <div className="card-bordered__creatives-title-image">
        <img src={`/social/${item.shortName}.svg`} alt={item.title} />
      </div>
      <div className="card-bordered__creatives-title-title card-bordered__title-flex">{item.title.split('').map((v, index) => (v === ' ' ? <div key={`${item.id}-${index}-creatives`}>&nbsp;</div> : <div key={`${item.id}-${index}-creatives`}>{v}</div>))}</div>
      <div className="card-bordered__creatives-title-num">
        <ArrowLeftSVG onClick={() => { if (creativeNum === 0) {} else {changeImage(creativeNum - 1)} }}/>
        <span>Креатив #{creativeNum + 1}</span>
        <ArrowRightSVG onClick={() => { if (creativeNum === item.creatives.length - 1) {} else {changeImage(creativeNum + 1)} }} /></div>
    </div>
    <div className="card-bordered__creatives-image">
      <img src={`${creativeImage}`} alt={`Креатив #${creativeNum}`} onError={() => setCreativeImage('/emptyimage/creatives.png')} />
    </div>
    {
      isPhone ?
      <>
      <div className="card-bordered__creatives-params">
        <Params type='likes' value={item.creatives[creativeNum].likeCount}/>
        <Params type='comments' value={item.creatives[creativeNum].commentCount}/>
        <Params type='share' value={item.creatives[creativeNum].shareCount}/>
      </div>
      <div className="card-bordered__creatives-params card-bordered__creatives-params-second">
        <Params type='coverage' value={item.creatives[creativeNum].coverageCount}/>
        <Params type='er' value={item.creatives[creativeNum].er} postFix="%"/>
      </div>
      </>

      :
      <div className="card-bordered__creatives-params">
        <Params type='coverage' value={item.creatives[creativeNum].coverageCount}/>
        <Params type='likes' value={item.creatives[creativeNum].likeCount}/>
        <Params type='comments' value={item.creatives[creativeNum].commentCount}/>
        <Params type='share' value={item.creatives[creativeNum].shareCount}/>
        <Params type='er' value={item.creatives[creativeNum].er} postFix="%"/>
      </div>
    }
    <div className="card-bordered__creatives-thumbs">
      {
        item.creatives.map((v, i) => (
          <CreativeThumb 
            key = {`creative-${item.id}-thumb-${i}`}
            image = {v.image}
            i = {i}
            creativeNum = {creativeNum}
            onClick = {(num) => changeImage(num)}
          />))
      }
    </div>
  </CardBordered>)
}

export default CreativeCard;