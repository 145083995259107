import React from "react";

import TopCommonStat from "./TopCommonStat";
import { observer } from "mobx-react-lite";
import DescriptionBlock from "../InfoBlock/Description";
import { ReactComponent as PieNSVG } from '../../svg/pie-n.svg'
import Pie3dDetail from "../Diagrams/Pie3dDetail";
import useWindowDimensions from "../../utils/Hooks";
import appStore from "../../Store/MainStore";

const ContentTopDetail = ({
  className = '',
  verticalId = 0,
}) => {
  const { isPhone } = useWindowDimensions();
  if (appStore.isLoading) {
    return null;
  }
  if (isPhone) {
    return (<div className={`bg`}>
        <div className={`container-all top-content top-content-detail`}>
          <DescriptionBlock isPhone/>
          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              <Pie3dDetail verticalId={verticalId} />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                <div className="top-content-pie-data__num"><PieNSVG /></div>
              </div>
            </div>
          </div>
          <div className="top-content__detail-parameters">
            <TopCommonStat />
          </div>
        </div>
      </div>)
  }

  return (<div className={`container-all top-content bg top-content-detail`}>
    <div className="container">
      <DescriptionBlock />
      <div className="top-content__main">
        <div className="top-content__main-column top-content__pie">
          <Pie3dDetail key={`pie3d-${verticalId}`} verticalId={verticalId} />
          <div className="top-content-pie-data-back"></div>
          <div className="top-content-pie-data">
            <div className="top-content-pie-data__num"><PieNSVG /></div>
          </div>
        </div>
        <div className="top-content__main-column">
          <TopCommonStat />
        </div>
      </div>
    </div>
  </div>)
}

export default observer(ContentTopDetail);