import React from "react";
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import useWindowDimensions from "../../utils/Hooks";
import LineCard from "../Diagrams/LineCard";
import ParameterIcon from "../Parameter/ParameterIcon";
import ParameterText from "../Parameter/ParameterText";
import ProgressBar from "../Progressbar/ProgressBar";
import Card from "./Card";
import NoData from "./NoData";

const VerticalCard = ({
  vertical,
}) => {
  const navigate = useNavigate();
  const { isPhone } = useWindowDimensions();

  return (
    <Card 
      className={`card-vertical ${vertical.type === 2 ? 'card-now' : ''}`} 
      onClick={() => {
        if (vertical.coverageCount) {
          navigate(`/campaigns/${vertical.id}`)
        } else {
          toast('Данные в процессе сбора')
        }
      }}>
      { vertical.type === 2 ? <div className="card-now__ribbon-wrapper"><div className="card-now__ribbon">NOW</div></div> : null }
      <div>
        <div className="card__title">{vertical.title}</div>
      </div>
      {isPhone ? null : <div className="card-vertical__date">{vertical.period}</div>}
      
      {
        vertical.coverageCount ?

        isPhone ?
        <div className="card-vertical__body-phone">
          <div className="card-vertical__body">
            <div className="card-vertical__diagram">
              <LineCard data={vertical.coverageHistory} isProgressHide={vertical.progress === null} />
              {vertical.progress !== null ? <ProgressBar value={vertical.progress} maxValue={isPhone ? 125 : 173} /> : null}
            </div>
            <div className="card-vertical__params">
              <div className="card-vertical__date">{vertical.period}</div>
              <div className="display-row">
                <ParameterText value={vertical.coverageCount} text="Охват" />
                <ParameterText value={vertical.publicCount} text="Площадок" />
              </div>
              <div className="display-row">
                <ParameterText value={vertical.postCount} text="Размещений" />
                <ParameterText value={vertical.creativeCount} text="Креативов" />
              </div>
            </div>
          </div>
          <div className="display-row card-vertical__params-second">
            {vertical.likeCount !== null ? <ParameterIcon key={`card-vertical-like`} param={{ value: vertical.likeCount, icon: 'likes', size: 'medium' }} /> : null}
            {vertical.shareCount !== null ? <ParameterIcon key={`card-vertical-share`} param={{ value: vertical.shareCount, icon: 'share', size: 'medium' }} /> : null}
            {vertical.commentCount !== null ? <ParameterIcon key={`card-vertical-comment`} param={{ value: vertical.commentCount, icon: 'comments', size: 'medium' }} /> : null}
            {vertical.erCount !== null ? <ParameterIcon key={`card-vertical-er`} param={{ value: vertical.erCount, icon: 'er', size: 'medium' }} /> : null}
          </div>

        </div>
        :
        <div className="card-vertical__body">
          <div className="card-vertical__diagram">
            <LineCard data={vertical.coverageHistory} isProgressHide={vertical.progress === null} />
            {vertical.progress !== null ? <ProgressBar value={vertical.progress} maxValue={isPhone ? 125 : 173} /> : null}
          </div>
          <div className="card-vertical__params">
            <ParameterText className="parameter-text-row" value={vertical.coverageCount} text="Текущий охват" />
            <div className="display-row">
              <ParameterText value={vertical.publicCount} text="Площадок" />
              <ParameterText value={vertical.postCount} text="Размещений" />
              <ParameterText value={vertical.creativeCount} text="Креативов" />
            </div>
            <div className="display-row">
              {vertical.likeCount !== null ? <ParameterIcon key={`card-vertical-like`} param={{ value: vertical.likeCount, icon: 'likes', size: 'medium' }} /> : null}
              {vertical.shareCount !== null ? <ParameterIcon key={`card-vertical-share`} param={{ value: vertical.shareCount, icon: 'share', size: 'medium' }} /> : null}
              {vertical.commentCount !== null ? <ParameterIcon key={`card-vertical-comment`} param={{ value: vertical.commentCount, icon: 'comments', size: 'medium' }} /> : null}
              {vertical.erCount !== null ? <ParameterIcon key={`card-vertical-er`} param={{ value: vertical.erCount, icon: 'er', size: 'medium' }} /> : null}
            </div>
          </div>
        </div>

        : <div className="card-vertical__body">
          <NoData />
        </div>
      }
    </Card>
  );
}

export default VerticalCard;