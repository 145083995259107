/* eslint-disable no-undef */
import { makeAutoObservable } from "mobx";
import { dateFormat } from "../utils/Formatter";
import { Gender, GenderAge, SocialNetworks } from "./constants";
import { toast } from "react-toastify";
import {
  mock12Vertical,
  mock13Vertical,
  mockVerticals,
  themeblocks,
} from "./mock";
import { calcCommonStat, calcLegend, calcPie } from '../utils/calc';
import { getVerticalExamples, getVerticalTarget, getVerticalAuditory, getVerticalSources, getVerticalDetailStatistics, getVerticalTopCreatives, getProgressKPI } from '../utils/verticalData';

const axios = require("axios");

const BASE_URL = process.env.REACT_APP_API_URL;

const sortData = (arr, field) => {
  return arr.sort((a, b) =>
    a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks

class AppStore {
  authToken = "";
  cmVerticalSort = "coverageCount";
  cmInfoStatusFilter = 1;
  cmVerticalFilter = 3;
  cmLegend = [];
  cmLegendCampaigns = [];

  cmVerticals = [];
  cmVerticalsAll = [];
  commonPie = [];
  commonPieID = new Date().getTime();
  commonStats = {
    coverageCount: 0,
    publicCount: 0,
    postCount: 0,
    creativeCount: 0,
    likeCount: 0,
    shareCount: 0,
    commentCount: 0,
    erCount: 0,
    verticals: 0,
    verticalsNow: 0,
  };
  detailDescription = {
    info: "",
    period: "",
    name: "",
  };
  detailPage = {
    common: {
      id: 0,
    },
    auditory: {
      city: null,
      gender: null,
      interest: null,
    },
    sources: null,
    snStats: {
      snTabs: [],
      snData: {},
      activeTab: 0,
      activeTabNum: 0,
    },
    creatives: null,
    examples: null,
    target: {
      snTabs: [],
      snData: {},
      activeTab: 0,
      activeTabNum: 0,
    },
    retarget: [],
  };
  fieldErrors = {
    login: "",
    password: "",
  };
  isLoading = true;
  route = "";
  commonTip = {
    isShow: false,
    num: 0,
  };
  themeBlock = [
    {
      id: 0,
      name: "",
      image: "",
      color: "",
      coverage: 0,
      public_count: 0,
      post_count: 0,
      replies: 0,
      mean_coverage: 0,
      err: 0,
      newsbreak_count: 0,
    },
  ];
  // cmVerticalsAll = [];
  commonPieCustomer = [];

  themeBlockSort = "coverage";
  infoBlockSort = "coverage";
  themeBlockAll = 0;
  infoBlockAll = 0;
  coverageInfoblockDiagram = [];
  legendCategory = [];
  detalPie = [];
  postCreativ = [];
  topComents = [];
  sources = [];
  creative = [];
  coverageThemeblockDiagram = [];
  userName = {};

  isAdmin = "";

  customerLogo = "";
  id = "";
  customerId = "";

  targetDescription = "";

  progresskpi = {
    kpi: 0,
    realCoverage: 0,
    points: [],
    percentCoverage: 0,
    blueLineLength: 0,
    greenLineLength: 0,
  };


  progressKPI = 10;
  blue = 10;
  green = 4;
  stat = 70;
  kpi = 20;
  points = 100


  constructor() {
    makeAutoObservable(this);
    const token = localStorage.getItem("bfvertical | token");
    const userId = localStorage.getItem("bfvertical | userId");
    console.log("1. token and userId", token, userId);
    if (token) {
      this.authToken = token;
      console.log("2", token, userId);

      if (userId === "null") {
        this.isAdmin = true;
      }

      if (userId === "null" && window.location.pathname === "/") {
        // это админ
        window.location.href = "/customers";
      } else if (userId && window.location.pathname === "/") {
        // это пользователь
        window.location.href = "/campaigns";
        console.log("Iduser", this.Iduser);
      }
    } else if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }

  setUserId(customer) {
    try {
      const userId = customer;
      localStorage.setItem("bfvertical | userId", userId);
      this.id = userId;
    } catch (error) {
      // toast.error('Проверьте правильность введённых символов');
    }
  }
  setToken(access_token) {
    try {
      const token = access_token;
      localStorage.setItem("bfvertical | token", token);
      this.authToken = token;
    } catch (error) {
      // toast.error('Проверьте правильность введённых символов');
    }
  }
  async postAuthentication(login, password) {
    this.isLoading = true;
    try {
      const response = await axios.post(`${BASE_URL}/login`, {
        username: login,
        password: password,
      });

      console.log("токен2", response.data.access_token);
      this.setToken(response.data.access_token);
      this.setUserId(response.data.customer);
      const id = response.data.customer;
      if (response.data.customer === null) {
        this.isAdmin = true;
        return { status: 200, user: null, isAdmin: true };
      } else if (response.data.customer !== null) {
        // this.getCustomerId(id);
        this.isAdmin = false;
        return { status: 200, user: id, isAdmin: false };
      }

      console.log(">>>> Main", response);
    } catch (error) {
      this.isLoading = false;
      let status = 200;
      if (status === 401) {
        toast.error("Неверный логин или пароль");
        localStorage.removeItem("bfvertical | token");
        return { status: 401 };
      }
    }
  }

  // так-то бы для красоты тут вынести отдельно кастомера в отдельный стор и порефакторить, но это если оооочень захочется

  // со страницы списка кампаний у админа
  async getCustomerUser(id) {
    this.isLoading = true;
    try {
      const response = await axios.get(
        `${BASE_URL}/customers/${id}?expand=campaigns`,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      );
      const customerLogo = response.data.logo;
      this.customerLogo = customerLogo;

      const customerId = response.data.id;
      this.customerId = customerId;

      const campaings = response.data.campaigns;

      console.log("111111111", response);

      const campaingsId = [];
      campaings.forEach((vertical) => {
        let _history = [];
        if (vertical.stat && vertical.stat.history) {
          _history = Object.values(vertical.stat.history).filter(
            (v) => v.coverage !== null
          );
        }
        campaingsId.push({
          id: vertical.id,
          title: vertical.name,
          coverageCount: vertical.stat ? +vertical.stat.coverage || 0 : null,
          publicCount: vertical.stat ? +vertical.stat.public_count || 0 : null,
          postCount: vertical.stat ? +vertical.stat.post_count || 0 : null,
          creativeCount: vertical.stat
            ? +vertical.stat.creative_count || 0
            : null,
          likeCount: vertical.stat ? +vertical.stat.like_count || null : null,
          shareCount: vertical.stat
            ? +vertical.stat.repost_count || null
            : null,
          commentCount: vertical.stat
            ? +vertical.stat.comment_count || null
            : null,
          erCount: vertical.stat ? +vertical.stat.er * 100 || null : null,
          type: +vertical.type_id,
          progress: vertical.progress === null ? null : +vertical.progress,
          coverageHistory: _history,
          period: `${dateFormat(vertical.date_start)} - ${dateFormat(
            vertical.date_finish
          )}`,
          dateStartTime: new Date(vertical.date_start).getTime(),
          color: vertical.color,
          status: vertical.status_id
        });
      });

      this.cmVerticals = campaingsId;
      this.cmVerticalsAll = campaingsId;


      console.log("cust:", campaingsId);

      this.setCampaignsFilterStatus(1);

      this.isLoading = false;

      return this;

      // console.log("Sector:", this.cmVerticals);
    } catch (error) {
      this.isLoading = false;
      let status = 200;

      if (error && error.response && error.response.status) {
        status = error.response.status;
      }

      if (status === 401) {
        toast.error("Неверный логин или пароль");
        localStorage.removeItem("bfvertical | token");
        return 401;
      } else {
        toast.error("Ошибка запроса данных");
        return 500;
      }
    }
  }




  async getCustomerId(id) {
    this.isLoading = true;
    try {
      const response = await axios.get(
        `${BASE_URL}/customers/${id}?expand=campaigns`,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      );
      const customerLogo = response.data.logo;
      this.customerLogo = customerLogo;
      const campaings = response.data.campaigns;
      console.log("222222", response);
      let _legend = [];
      const _pie = [];
      let _commonStat = {
        coverageCount: 0,
        publicCount: 0,
        postCount: 0,
        creativeCount: 0,
        likeCount: 0,
        shareCount: 0,
        commentCount: 0,
        erCount: 0,
      };
      const campaingsId = [];
      campaings.forEach((vertical) => {
        let _history = [];
        if (vertical.stat && vertical.stat.history) {
          _history = Object.values(vertical.stat.history).filter(
            (v) => v.coverage !== null
          );
        }
        campaingsId.push({
          id: vertical.id,
          title: vertical.name,
          coverageCount: vertical.stat ? +vertical.stat.coverage || 0 : null,
          publicCount: vertical.stat ? +vertical.stat.public_count || 0 : null,
          postCount: vertical.stat ? +vertical.stat.post_count || 0 : null,
          creativeCount: vertical.stat
            ? +vertical.stat.creative_count || 0
            : null,
          likeCount: vertical.stat ? +vertical.stat.like_count || null : null,
          shareCount: vertical.stat
            ? +vertical.stat.repost_count || null
            : null,
          commentCount: vertical.stat
            ? +vertical.stat.comment_count || null
            : null,
          erCount: vertical.stat ? +vertical.stat.er * 100 || null : null,
          type: +vertical.type_id,
          progress: vertical.progress === null ? null : +vertical.progress,
          coverageHistory: _history,
          period: `${dateFormat(vertical.date_start)} - ${dateFormat(
            vertical.date_finish
          )}`,
          color: vertical.color,
        });
      });

      this.cmVerticals = campaingsId;

      console.log("custPoId2:", this.cmVerticals);

      // _____________________________

      campaingsId.forEach((vertical) => {
        _commonStat = {
          coverageCount: _commonStat.coverageCount + vertical.coverageCount,
          publicCount: _commonStat.publicCount + vertical.publicCount,
          postCount: _commonStat.postCount + vertical.postCount,
          creativeCount: _commonStat.creativeCount + vertical.creativeCount,
          likeCount: _commonStat.likeCount + (vertical.likeCount || 0),
          shareCount: _commonStat.shareCount + (vertical.shareCount || 0),
          commentCount: _commonStat.commentCount + (vertical.commentCount || 0),
          erCount: 0,
        };
      });

      _commonStat.verticals = campaingsId.length;
      this.commonStats = _commonStat;

      campaingsId.forEach((vertical) => {
        _legend.push({
          id: vertical.id,
          title: vertical.title,
          coverageCount: vertical.coverageCount,
          coveragePercent:
            (vertical.coverageCount / _commonStat.coverageCount) * 100,
          color: vertical.color,
        });
      });
      _legend = _legend.sort((a, b) =>
        a.coverageCount < b.coverageCount
          ? 1
          : b.coverageCount < a.coverageCount
            ? -1
            : 0
      );
      // this.cmLegend = _legend;
      this.cmLegendCampaigns = _legend;

      let accAngle = 0;
      let prevLength = 0;
      let height = 7.5; //_legend.filter(v => v.coverageCount).length + 1;
      const minHeight = 1;
      let prevData = null;
      const _realLegend = _legend.filter((v) => v.coveragePercent);
      const countSectors = _realLegend.length || 1;
      const delta = countSectors < 2 ? 2 : (height - minHeight) / countSectors;
      _realLegend.forEach((vertical, num) => {
        accAngle += prevLength;
        height = prevData === vertical.coverageCount ? height : height - delta;
        prevLength =
          Math.PI * 2 * (vertical.coverageCount / _commonStat.coverageCount);
        prevData = vertical.coverageCount;
        _pie.push({
          id: vertical.id,
          color: vertical.color,
          coverage: vertical.coverageCount,
          coveragePercent:
            (vertical.coverageCount / _commonStat.coverageCount) * 100,
          num,
          label: { num: num },
          startAngle: accAngle,
          lengthSector: accAngle + prevLength,
          endLengthSector: prevLength,
          height,
        });
      });
      this.commonPie = _pie;
      // this.isLoading = false;

      return this;

      // console.log("Sector:", this.cmVerticals);
    } catch (error) {
      this.isLoading = false;
      let status = 200;

      if (error && error.response && error.response.status) {
        status = error.response.status;
      }

      if (status === 401) {
        toast.error("Неверный логин или пароль");
        localStorage.removeItem("bfvertical | token");
        return 401;
      } else {
        toast.error("Ошибка запроса данных");
        return 500;
      }
    }
  }

  async getVerticals(id) {
    this.isLoading = true;
    try {
      const response = await axios.get(`${BASE_URL}/customers/${id}`, {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });
      console.log(">>>> пользователь", response);

      const resServer = response.data;
      // const resServer = mockVerticals;// response.data;
      // this.fieldErrors = {
      //   login: "",
      //   password: "",
      // };
      console.log(">>>> QQQQQQQQQQQQQQQQQ", data);
      let _legend = [];
      const _pie = [];
      let _commonStat = {
        coverageCount: 0,
        publicCount: 0,
        postCount: 0,
        creativeCount: 0,
        likeCount: 0,
        shareCount: 0,
        commentCount: 0,
        erCount: 0,
      };
      const verticals = [];
      console.log('++++++++', verticals)

      resServer.forEach((vertical) => {
        let _history = [];
        if (vertical.stat && vertical.stat.history) {
          _history = Object.values(vertical.stat.history).filter(
            (v) => v.coverage !== null
          );
        }
        verticals.push({
          id: vertical.id,
          title: vertical.name,
          coverageCount: vertical.stat ? +vertical.stat.coverage || 0 : null,
          publicCount: vertical.stat ? +vertical.stat.public_count || 0 : null,
          postCount: vertical.stat ? +vertical.stat.post_count || 0 : null,
          creativeCount: vertical.stat
            ? +vertical.stat.creative_count || 0
            : null,
          likeCount: vertical.stat ? +vertical.stat.like_count || null : null,
          shareCount: vertical.stat
            ? +vertical.stat.repost_count || null
            : null,
          commentCount: vertical.stat
            ? +vertical.stat.comment_count || null
            : null,
          erCount: vertical.stat ? +vertical.stat.er * 100 || null : null,
          type: +vertical.type_id,
          progress: vertical.progress === null ? null : +vertical.progress,
          coverageHistory: _history,
          period: `${dateFormat(vertical.date_start)} - ${dateFormat(
            vertical.date_finish
          )}`,
          color: vertical.color,
        });

        console.log('++++++++++:', verticals)
      });

      this.cmVerticalsAll = verticals;

      const _filteredVerticals =
        this.cmVerticalFilter < 3
          ? verticals.filter((v) => v.type === this.cmVerticalFilter)
          : verticals;

      this.cmVerticals = _filteredVerticals.sort((a, b) =>
        a[this.cmVerticalSort] < b[this.cmVerticalSort]
          ? 1
          : b[this.cmVerticalSort] < a[this.cmVerticalSort]
            ? -1
            : 0
      );
   


      // Фильтер активный архивные 

      // const _filteredInfoStatus =
      //   this.cmInfoStatusFilter < 2
      //     ? verticals.filter((v) => v.type === this.cmInfoStatusFilter)
      //     : verticals;

      // this.cmInfoStatusFilter = _filteredInfoStatus.sort((a, b) =>
      //   a[this.cmVerticalSort] < b[this.cmVerticalSort]
      //     ? 1
      //     : b[this.cmVerticalSort] < a[this.cmVerticalSort]
      //       ? -1
      //       : 0
      // );

      // конец

      verticals.forEach((vertical) => {
        _commonStat = {
          coverageCount: _commonStat.coverageCount + vertical.coverageCount,
          publicCount: _commonStat.publicCount + vertical.publicCount,
          postCount: _commonStat.postCount + vertical.postCount,
          creativeCount: _commonStat.creativeCount + vertical.creativeCount,
          likeCount: _commonStat.likeCount + (vertical.likeCount || 0),
          shareCount: _commonStat.shareCount + (vertical.shareCount || 0),
          commentCount: _commonStat.commentCount + (vertical.commentCount || 0),
          erCount: 0,
        };
      });

      _commonStat.erCount = +_commonStat.coverageCount
        ? (((_commonStat.likeCount || 0) +
          (_commonStat.shareCount || 0) +
          (+_commonStat.commentCount || 0)) /
          _commonStat.coverageCount) *
        100
        : 0;
      _commonStat.verticalsNow = verticals.filter(
        (v) => v.type_id === 2
      ).length;

      _commonStat.verticals = verticals.length;
      _commonStat.verticalsNow = verticals.filter((v) => v.type === 2).length;
      this.commonStats = _commonStat;

      verticals.forEach((vertical) => {
        _legend.push({
          id: vertical.id,
          title: vertical.title,
          coverageCount: vertical.coverageCount,
          coveragePercent:
            (vertical.coverageCount / _commonStat.coverageCount) * 100,
          color: vertical.color,
        });
      });
      _legend = _legend.sort((a, b) =>
        a.coverageCount < b.coverageCount
          ? 1
          : b.coverageCount < a.coverageCount
            ? -1
            : 0
      );
      // this.cmLegend = _legend;
      this.cmLegendCampaigns = _legend;

      let accAngle = 0;
      let prevLength = 0;
      let height = 7.5; //_legend.filter(v => v.coverageCount).length + 1;
      const minHeight = 1;
      let prevData = null;
      const _realLegend = _legend.filter((v) => v.coveragePercent);
      const countSectors = _realLegend.length || 1;
      const delta = countSectors < 2 ? 2 : (height - minHeight) / countSectors;
      _realLegend.forEach((vertical, num) => {
        accAngle += prevLength;
        height = prevData === vertical.coverageCount ? height : height - delta;
        prevLength =
          Math.PI * 2 * (vertical.coverageCount / _commonStat.coverageCount);
        prevData = vertical.coverageCount;
        _pie.push({
          id: vertical.id,
          color: vertical.color,
          coverage: vertical.coverageCount,
          coveragePercent:
            (vertical.coverageCount / _commonStat.coverageCount) * 100,
          num,
          label: { num: num },
          startAngle: accAngle,
          lengthSector: accAngle + prevLength,
          endLengthSector: prevLength,
          height,
        });
      });
      this.commonPie = _pie;
      this.isLoading = false;

      return this;
    } catch (error) {
      this.isLoading = false;
      let status = 200;

      if (error && error.response && error.response.status) {
        status = error.response.status;
      }

      if (status === 401) {
        toast.error("Неверный логин или пароль");
        localStorage.removeItem("bfvertical | token");
        return 401;
      } else {
        toast.error("Ошибка запроса данных");
        return 500;
      }
    }
  }

  async getVerticalById(id) {
    this.isLoading = true;
    this.detailPage = {
      common: {
        id: 0,
      },
      auditory: {
        city: null,
        gender: null,
        interest: null,
      },
      sources: null,
      snStats: {
        snTabs: [],
        snData: {},
        activeTab: 0,
        activeTabNum: 0,
      },
      creatives: null,
      examples: null,
      target: {
        snTabs: [],
        snData: {},
        activeTab: 0,
        activeTabNum: 0,
      },
      reTarget: null,
    };
   
    

    try {
      const response = await axios.get(
        `${BASE_URL}/campaigns/${id}?expand=description,sources,topCreatives,postExamples,audienceCityStructure,audienceInterestStructure,audienceSexAgeStructure,socialNetworksStat,target,retarget,coverage_kpi`,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      );

      console.log(">>>> compaignsDetal", response, id);

      const resServer = response.data;
      // const resServer = mock13Vertical; // response.data;

      console.log(">>>> mock", resServer);

      const _pie = [];

      let _commonStat = {
        coverageCount: resServer.stat.coverage,
        publicCount: resServer.stat.public_count,
        postCount: resServer.stat.post_count,
        creativeCount: resServer.stat.creative_count,
        likeCount: resServer.stat.like_count,
        shareCount: resServer.stat.repost_count,
        commentCount: resServer.stat.comment_count,
        erCount: resServer.stat.er * 100,
      };

      this.commonStats = _commonStat;
      this.detailDescription = {
        id: resServer.id,
        name: resServer.name,
        info: resServer.description,
        period: `${dateFormat(resServer.date_start)} - ${dateFormat(
          resServer.date_finish
        )}`,
      };

      console.log("idddd", this.detailDescription);

      const examples = await getVerticalExamples(resServer.postExamples);
  

      console.log('KPI', resServer.stat.coverage, resServer.coverage_kpi)

      const realCoverage = resServer.stat.coverage
      const progresskpi = getProgressKPI(resServer.coverage_kpi, realCoverage);
     
      this.progresskpi=progresskpi;
      console.log('KPI', progresskpi)

    

      const target = getVerticalTarget(resServer.target);
     
      const retarget = getVerticalTarget(resServer.retarget);

      const auditory = await getVerticalAuditory(resServer.audienceCityStructure, resServer.audienceInterestStructure, resServer.audienceSexAgeStructure);


      const snTabs = [];
      const snData = {};
      let snStats = {
        snTabs: [],
        snData: {},
        activeTab: 0,
        activeTabNum: 0,
      };
      SocialNetworks.forEach((sn) => {
        if (
          resServer.socialNetworksStat[sn.id] &&
          resServer.socialNetworksStat[sn.id].coverage !== null
        ) {
          const data = resServer.socialNetworksStat[sn.id];
          snTabs.push(sn);
          const snDiagram = [];
          data.history.forEach((diagramData) => {
            if (
              diagramData.coverage !== null ||
              diagramData.post_count !== null
            ) {
              snDiagram.push({
                coverage: diagramData.coverage || 0,
                date: diagramData.date,
                postCount: diagramData.post_count || 0,
              });
            }
          });
          snData[sn.id] = {
            id: sn.id,
            commentCount: data.comment_count,
            coverageCount: data.coverage || 0,
            creativeCount: data.creative_count || 0,
            erCount: data.er === null ? null : data.er * 100,
            snDiagram,
            likeCount: data.like_count,
            postCount: data.post_count || 0,
            publicCount: data.public_count || 0,
            shareCount: data.repost_count,
          };
        }
      });
      if (snTabs.length) {
        snStats = {
          snTabs: snTabs,
          snData: snData,
          activeTab: snTabs[0].id,
          activeTabNum: 0,
        };

        let accAngle = 0;
        let prevLength = 0;
        let height = 4;
        let prevData = null;
        let tmpPie = [];
        snTabs.forEach((sn) => {
          tmpPie.push({
            id: sn.id,
            color: sn.color,
            coverage: snData[sn.id].coverageCount,
            coveragePercent:
              (snData[sn.id].coverageCount / _commonStat.coverageCount) * 100,
            isSN: true,
            num: sn.shortName,
            label: { num: sn.shortName },
          });
        });

        tmpPie = sortData(tmpPie, "coverage");
        const delta = tmpPie.length < 2 ? 2 : height / tmpPie.length;
        prevData = tmpPie[0].coverage;
        tmpPie.forEach((sector) => {
          accAngle += prevLength;
          height = prevData === sector.coverage ? height : height - delta;
          prevLength =
            Math.PI * 2 * (sector.coverage / _commonStat.coverageCount);
          prevData = sector.coverage;
          _pie.push({
            ...sector,
            startAngle: accAngle,
            lengthSector: accAngle + prevLength,
            endLengthSector: prevLength,
            height,
          });
        });
      }

      const sources = await getVerticalSources(resServer.sources);

      const creatives = await getVerticalTopCreatives(resServer.topCreatives);

      this.commonPie = _pie;
    

      this.detailPage = {
        target,
        retarget,
        examples,
        auditory,
        snStats,
        creatives,
        sources,
        common: {
          id,
        },
      };

      // console.log(">>> ", this.detailPage);
      this.isLoading = false;

      return this;
    } catch (error) {
      this.isLoading = false;
      let status = 200;

      if (error && error.response && error.response.status) {
        status = error.response.status;
      }

      if (status === 401) {
        toast.error("Неверный логин или пароль");
        localStorage.removeItem("bfvertical | token");
        return 401;
      } else if (status === 404) {
        toast.error("Данные не найдены");
        return 404;
      } else {
        toast.error("Ошибка запроса данных");
        return 500;
      }
    }
  }

  async getCustomersData() {
    this.isLoading = true;

    try {
      const response = await axios.get(`${BASE_URL}/customers`, {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });
      // Mock
      // const data = themeblocks;
      // No mock
      const data = response.data;
      console.log(">>>> customers", data);
      const sort = data.map((item) => {
        const tmp = {
          id: item.id, //+
          name: item.name, //+
          image: item.logo, //+
          color: item.color, //+
          campaignsCount: item.campaignsCount, //+
          coverage: item.stat.coverage, //+
          public_count: item.stat.public_count, //+
          post_count: item.stat.post_count, //+
          replies: item.stat.comment_count, //+
          mean_coverage: item.mean_coverage,
          err: item.stat.er * 100, //+
          newsbreak_count: item.stat.creative_count,
          reactions: item.stat.like_count, //+
          repost: item.stat.repost_count, //+
        };
        return tmp;
      });

      this.themeBlock = sortData(sort, this.themeBlockSort);

      // console.log("uuuuuuuuu", this.customerLogo);

      const themeBlocLegend = [];
      const pie = [];
      let totalCoverage = 0;
      this.themeBlock.forEach((item) => {
        totalCoverage += item.coverage;
      });

      let url = "info";
      this.themeBlock.forEach((themeblock) => {
        themeBlocLegend.push({
          id: themeblock.id,
          title: themeblock.name,
          coverageCount: themeblock.coverage,
          coveragePercent: (themeblock.coverage / totalCoverage) * 100,
          color: themeblock.color,
          url,
          type: 2,
        });
      });
      console.log("ThemeBlockLegend", themeBlocLegend);

      let legend = [];
      let accAngle = 0;
      let prevLength = 0;
      let height = 5; //legend.filter(v => v.coverageCount).length + 1;
      const minHeight = 2;
      let prevData = null;
      const realLegend = themeBlocLegend.filter((v) => v.coveragePercent > 0.5);
      const countSectors = realLegend.length || 1;
      // const delta = countSectors < 1 ? 1 : (height - minHeight); //   / countSectors
      realLegend.forEach((themeblock, num) => {
        accAngle += prevLength;
        // height = prevData === themeblock.coverageCount ? height : height - delta;
        prevLength = Math.PI * 2 * (themeblock.coverageCount / totalCoverage);
        prevData = themeblock.coverageCount;
        pie.push({
          id: themeblock.id,
          color: themeblock.color,
          coverage: themeblock.coverageCount,
          coveragePercent: (themeblock.coverageCount / totalCoverage) * 100,
          num,
          label: { num: num },
          startAngle: accAngle,
          lengthSector: accAngle + prevLength,
          endLengthSector: prevLength,
          height,
          url,
        });
      });

      this.commonPieCustomer = pie;
      this.cmLegend = themeBlocLegend;
      this.themeBlockAll = themeBlocLegend.length;

      console.log("pie", pie);

      console.log("Legend", this.themeBlockAll);

      console.log(">>>>>>>ThemBlocks", response);

      const resServer = response.data;
      // const resServer = mockVerticals;// response.data;
      this.fieldErrors = {
        login: "",
        password: "",
      };

      let _legend = [];
      const _pie = [];
      let _commonStat = {
        coverageCount: 0,
        publicCount: 0,
        postCount: 0,
        creativeCount: 0,
        likeCount: 0,
        shareCount: 0,
        commentCount: 0,
        erCount: 0,
      };
      const verticals = [];

      resServer.forEach((vertical) => {
        let _history = [];
        if (vertical.stat && vertical.stat.history) {
          _history = Object.values(vertical.stat.history).filter(
            (v) => v.coverage !== null
          );
        }
        verticals.push({
          id: vertical.id,
          title: vertical.name,
          coverageCount: vertical.stat ? +vertical.stat.coverage || 0 : null,
          publicCount: vertical.stat ? +vertical.stat.public_count || 0 : null,
          postCount: vertical.stat ? +vertical.stat.post_count || 0 : null,
          creativeCount: vertical.stat
            ? +vertical.stat.creative_count || 0
            : null,
          likeCount: vertical.stat ? +vertical.stat.like_count || null : null,
          shareCount: vertical.stat
            ? +vertical.stat.repost_count || null
            : null,
          commentCount: vertical.stat
            ? +vertical.stat.comment_count || null
            : null,
          erCount: vertical.stat ? +vertical.stat.er * 100 || null : null,
          type: +vertical.type_id,
          progress: vertical.progress === null ? null : +vertical.progress,
          coverageHistory: _history,
          period: `${dateFormat(vertical.date_start)} - ${dateFormat(
            vertical.date_finish
          )}`,
          color: vertical.color,
        });
      });

      this.cmVerticalsAll = verticals;

      const _filteredVerticals =
        this.cmVerticalFilter < 3
          ? verticals.filter((v) => v.type === this.cmVerticalFilter)
          : verticals;

      this.cmVerticals = _filteredVerticals.sort((a, b) =>
        a[this.cmVerticalSort] < b[this.cmVerticalSort]
          ? 1
          : b[this.cmVerticalSort] < a[this.cmVerticalSort]
            ? -1
            : 0
      );

      verticals.forEach((vertical) => {
        _commonStat = {
          coverageCount: _commonStat.coverageCount + vertical.coverageCount,
          publicCount: _commonStat.publicCount + vertical.publicCount,
          postCount: _commonStat.postCount + vertical.postCount,
          creativeCount: _commonStat.creativeCount + vertical.creativeCount,
          likeCount: _commonStat.likeCount + (vertical.likeCount || 0),
          shareCount: _commonStat.shareCount + (vertical.shareCount || 0),
          commentCount: _commonStat.commentCount + (vertical.commentCount || 0),
          erCount: 0,
        };
      });

      _commonStat.erCount = +_commonStat.coverageCount
        ? (((_commonStat.likeCount || 0) +
          (_commonStat.shareCount || 0) +
          (+_commonStat.commentCount || 0)) /
          _commonStat.coverageCount) *
        100
        : 0;
      _commonStat.verticalsNow = verticals.filter(
        (v) => v.type_id === 2
      ).length;

      _commonStat.verticals = verticals.length;
      _commonStat.verticalsNow = verticals.filter((v) => v.type === 2).length;
      this.commonStats = _commonStat;

      verticals.forEach((vertical) => {
        _legend.push({
          id: vertical.id,
          title: vertical.title,
          coverageCount: vertical.coverageCount,
          coveragePercent:
            (vertical.coverageCount / _commonStat.coverageCount) * 100,
          color: vertical.color,
        });
      });
      _legend = _legend.sort((a, b) =>
        a.coverageCount < b.coverageCount
          ? 1
          : b.coverageCount < a.coverageCount
            ? -1
            : 0
      );
      this.isLoading = false;
      // this.cmLegend = _legend;
      return 200;
    } catch (error) {
      this.isLoading = false;
      let status = 200;

      if (error && error.response && error.response.status) {
        status = error.response.status;
      }

      if (status === 401) {
        toast.error("Неверный логин или пароль");
        localStorage.removeItem("bfvertical | token");
        return 401;
      } else {
        toast.error("Ошибка запроса данных");
        return 500;
      }
    }
  }

  cmChangeFilterSort() {
    const verticals = this.cmInfoStatusFilter === 0 ? this.cmVerticalsAll : this.cmVerticalsAll.filter(item => item.status === this.cmInfoStatusFilter);

    const _filteredVerticals =
      this.cmVerticalFilter < 3
        ? verticals.filter((v) => v.type === this.cmVerticalFilter)
        : verticals;
    this.cmVerticals = _filteredVerticals.sort((a, b) =>
      a[this.cmVerticalSort] < b[this.cmVerticalSort]
        ? 1
        : b[this.cmVerticalSort] < a[this.cmVerticalSort]
          ? -1
          : 0
    );
  }



  handleCommonTip(isShow, num) {
    this.commonTip = {
      isShow,
      num,
    };
  }

  setCampaignsFilterStatus(status) {
    this.cmInfoStatusFilter = status;
    let includeCampaignsIds = [];
    if (this.cmInfoStatusFilter === 0) {
      includeCampaignsIds = this.cmVerticalsAll.map(item => item.id)
    } else {
      includeCampaignsIds = this.cmVerticalsAll.filter(item => item.status === this.cmInfoStatusFilter).map(item => item.id)
    }

    const _commonStat = calcCommonStat(this.cmVerticalsAll, includeCampaignsIds);
    this.commonStats = _commonStat;

    const _legend = calcLegend(this.cmVerticalsAll, _commonStat, includeCampaignsIds);
    this.cmLegendCampaigns = _legend;

    const _pie = calcPie(_legend, _commonStat)
    this.commonPie = _pie;
    this.commonPieID = new Date().getTime();
    this.cmChangeFilterSort();
  }
}

const appStore = new AppStore();

export default appStore;
