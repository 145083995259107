import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import appStore from "../../Store/MainStore";
import { ReactComponent as ArrowSVG } from "../../svg/arrow_left.svg";
import { ProgressKPI } from "../Progressbar/ProgressKPI";

const DescriptionBlock = ({ isPhone }) => {
  const navigator = useNavigate();

  if (isPhone) {
    return (
      <>
        <div className="description-block">
          <div className="description-block__top">
            <div
              onClick={() => navigator(`/customers/${appStore.customerId}`)}
              className="description-block__comeback"
            >
              <ArrowSVG />
            </div>
            <div className="description-block__top-text">
              <div className="description-block__title">
                {appStore.detailDescription.name}
              </div>
              <div className="description-block__period">
                {appStore.detailDescription.period}
              </div>
            </div>
          </div>
          <div className="description-block__info">
            {appStore.detailDescription.info}
          </div>
        </div>
        <ProgressKPI />
      </>
    );
  }
  return (
    <>
      <div className="description-block">
        <div className="description-block__top">
          <div
            onClick={() => navigator(`/customers/${appStore.customerId}`)}
            className="description-block__comeback"
          >
            <ArrowSVG />
          </div>
          <div className="description-block__title">
            {appStore.detailDescription.name}
          </div>
          <div className="description-block__period">
            {appStore.detailDescription.period}
          </div>
        </div>
        <div className="description-block__info">
          {appStore.detailDescription.info}
        </div>
      </div>
      <ProgressKPI />
    </>
  );
};

export default observer(DescriptionBlock);
