import React from "react";

import Legend from "./Legend";
import TopCommonStat from "./TopCommonStat";
import Pie3d from "../Diagrams/Pie3d";
import { pluralWords } from "../../utils/Formatter";
import { observer } from "mobx-react-lite";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import LegendMobile from "./LegendMobile";
import Pie3dCustomer from "../Diagrams/PieCustomer";

// const filterItems = [
//   { label: 'В работе', id: 1},
//   { label: 'Архивные', id: 2},
//   { label: 'Все', id: null},
// ]

// const FilterComponent = observer(() => {
//   return (<div className="infoblock__header-tab top-content__info-filter">
//     {filterItems.map(item => (<div key={`filter-${item.id}`} className={`infoblock__header-tab-item ${appStore.cmInfoStatusFilter === item.id ? 'infoblock__header-tab-item_active' : ''}`} onClick={() => { appStore.cmInfoStatusFilter = item.id; appStore.getVerticals();}}>{item.label}</div>))}
//   </div>)
// })

const ContentTopAdmin = ({ isLegend = false, className = "" }) => {
  const { isPhone } = useWindowDimensions();

  return (
    <div className={`top-content bg ${className}`}>
      {isPhone ? (
        <div className="container">
          {/* <FilterComponent /> */}
          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              <Pie3dCustomer isPhone />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                {/* <div className="top-content-pie-data__now">
                {appStore.commonStats.verticalsNow}
              </div> */}
                <div className="top-content-pie-data__num">
                  {appStore.commonStats.verticals}
                </div>
                <div className="top-content-pie-data__theme">
                  {pluralWords(appStore.commonStats.verticals, [
                    "клиент",
                    "клиента",
                    "клиентов",
                  ])}
                </div>
                {/* <div className="top-content-pie-data__vertical">{pluralWords(appStore.commonStats.verticals, ['вертикаль', 'вертикали', 'вертикалей'])}</div> */}
              </div>
            </div>
          </div>
          <div className="top-content__legends-mobile">
            <LegendMobile />
          </div>
          <div className="">
            <TopCommonStat />
          </div>
        </div>
      ) : (
        <div className="container">
          {/* <FilterComponent /> */}
        
          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              {/* <PieDiagram /> */}
              <Pie3dCustomer />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                {/* <div className="top-content-pie-data__now">
                {appStore.commonStats.verticalsNow}
              </div> */}
                <div className="top-content-pie-data__num">
                  {(appStore.themeBlockAll ) -
                    (appStore.commonStats.verticalsNow || 0)}
                </div>
                <div className="top-content-pie-data__theme">
                  {pluralWords(
                    (appStore.themeBlockAll ) -
                      (appStore.commonStats.verticalsNow || 0),
                    ["клиент", "клиента", "клиентов"]
                  )}
                </div>
                {/* <div className="top-content-pie-data__vertical">{pluralWords((appStore.commonStats.verticals || 0) - (appStore.commonStats.verticalsNow || 0), ['вертикаль', 'вертикали', 'вертикалей'])}</div> */}
              </div>
            </div>
            <div className="top-content__main-column">
              <TopCommonStat />
            </div>
          </div>
          {isLegend && (
            <div className="top-content__legends">
              <Legend />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ContentTopAdmin);
