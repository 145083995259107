import React, { useEffect } from "react";
import appStore from "../../Store/MainStore";
import { ReactComponent as NotFoundSVG } from '../../svg/404.svg'

const NotFoundPage = () => {

  useEffect(() => {
    appStore.isLoading = false;
  }, []);

  return (
    <div className="not-found">
      <NotFoundSVG />
      <div className="not-found__text">Страница не найдена</div>
    </div>
  )
}

export default NotFoundPage;