import React from "react";

import Legend from "./Legend";
import TopCommonStat from "./TopCommonStat";
import Pie3d from "../Diagrams/Pie3d";
import { pluralWords } from "../../utils/Formatter";
import { observer } from "mobx-react-lite";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import LegendMobile from "./LegendMobile";
import LegendCampaigns from "./LegendCampaigns";
import LegendMobileCampaigns from "./LegendMobileCampaigns";

// const filterItems = [
//   { label: "В работе", id: 1 },
//   { label: "Архивные", id: 2 },
//   { label: "Все", id: null },
// ];

const filterItems = [
  { label: "Все", id: 0 },
  { label: "Активные", id: 1 },
  { label: "Архивные", id: 2 },
];

const FilterComponent = observer(() => {
  return (
    <div className="infoblock__header-tab top-content__info-filter">
      {filterItems.map((item) => (
        <div
          key={`filter-${item.id}`}
          className={`infoblock__header-tab-item ${
            appStore.cmInfoStatusFilter === item.id
              ? "infoblock__header-tab-item_active"
              : ""
          }`}
          onClick={() => {
            appStore.setCampaignsFilterStatus(item.id);
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
});

const ContentTop = ({ isLegend = false, className = "" }) => {
  const { isPhone } = useWindowDimensions();

  const verticalsCount = appStore.cmLegendCampaigns.length;
  const verticalsNowCount = appStore.cmLegendCampaigns.filter(item => item.verticalType === 2).length;

  return (
    <div className={`top-content bg ${className}`}>
      {isPhone ? (
        <div className="container">
          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              <Pie3d isPhone />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                {/* <div className="top-content-pie-data__now">
                {appStore.commonStats.verticalsNow}
              </div> */}
                <div className="top-content-pie-data__num">
                  {appStore.commonStats.verticals}
                </div>
                <div className="top-content-pie-data__theme">
                  {pluralWords(appStore.commonStats.verticals, [
                    "кампания",
                    "кампании",
                    "кампаний",
                  ])}
                </div>
                {/* <div className="top-content-pie-data__vertical">{pluralWords(appStore.commonStats.verticals, ['вертикаль', 'вертикали', 'вертикалей'])}</div> */}
              </div>
            </div>
          </div>
          <div className="top-content__logo-mobile">
            <div className="top-content__logo-mobile-filter">
              <img className="" src={appStore.customerLogo}></img>
              <FilterComponent />
            </div>
          </div>
          <div className="top-content__legends-mobile">
            <LegendMobileCampaigns />
          </div>
          <div className="">
            <TopCommonStat />
          </div>
        </div>
      ) : (
        <div className="container">
          {/* <div>{appStore.customerLogo}</div> */}

          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              {/* <PieDiagram /> */}
              <Pie3d />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                {/* <div className="top-content-pie-data__now">
                {appStore.commonStats.verticalsNow}
              </div> */}
                <div className="top-content-pie-data__num">
                {verticalsCount}
                </div>
                <div className="top-content-pie-data__theme">
                  {pluralWords(
                    (verticalsCount),
                    ["кампания", "кампании", "кампаний"]
                  )}
                </div>
                {/* <div className="top-content-pie-data__vertical">{pluralWords((appStore.commonStats.verticals || 0) - (appStore.commonStats.verticalsNow || 0), ['вертикаль', 'вертикали', 'вертикалей'])}</div> */}
              </div>
            </div>
            <div className="top-content__main-column">
              <div className="top-content__logo">
                <img className="" src={appStore.customerLogo}></img>
                <FilterComponent />
              </div>
              <TopCommonStat />
            </div>
          </div>
          {isLegend && (
            <div className="top-content__legends">
              <LegendCampaigns />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ContentTop);
