import React from "react";

const CardBordered = ({
  className = '',
  children
}) => {

  return (
    <div className={`card-bordered ${className}`}>
      <div className="card-bordered__body">
        {children}
      </div>
    </div>
  )
};

export default CardBordered;