import { Gender, GenderAge, SocialNetworks } from "../Store/constants";

const sortData = (arr, field) => {
  return arr.sort((a, b) =>
    a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0
  );
};

export const getVerticalExamples = (postExamples) => {
  const examples = [];

  if (postExamples && postExamples.length) {
    SocialNetworks.forEach((sn) => {
      const _arrExamples = postExamples
        .filter((v) => v.social_network_id === sn.id)
        .map((v) => ({ sn: v.social_network_id, url: v.url }));
      if (_arrExamples.length) {
        examples.push({
          title: sn.title,
          shortName: sn.shortName,
          examples: _arrExamples,
        });
      }
    });
  }

  return examples;
}
export const getProgressKPI = (coverage_kpi, realCoverage) => {

  const progresskpi = {
    kpi: coverage_kpi,
    realCoverage: realCoverage,
    points: [],
    percentCoverage: 0,
    blueLineLength: 0,
    greenLineLength: 0,
  };



  progresskpi.percentCoverage = progresskpi.kpi
    ? Math.ceil((progresskpi.realCoverage / progresskpi.kpi) * 100)
    : 0;

  const pointsCount =
    progresskpi.percentCoverage < 100
      ? 11
      : Math.ceil(progresskpi.percentCoverage / 10) + 1;
  progresskpi.points = [...Array(pointsCount).keys()];

  if (progresskpi.percentCoverage) {
    const maxPercents = progresskpi.points.slice(-1);
    if (progresskpi.percentCoverage > 100) {
      progresskpi.blueLineLength = +(1000 / maxPercents[0]).toFixed(2);
      progresskpi.greenLineLength = +(
        (10 * progresskpi.percentCoverage) /
        maxPercents[0]
      ).toFixed(2);
    } else {
      progresskpi.blueLineLength = +(
        (100 * progresskpi.percentCoverage) /
        100
      ).toFixed(2);
    }
  }
  return progresskpi
};

export const getVerticalTarget = (resTarget) => {
  const target = {
    activeTab: 0,
    activeTabNum: 0,
    isShow: false,
    description: resTarget?.description || '',
    socialNetworks: []
  }

  if (resTarget.stats && resTarget.postExamples) {
    SocialNetworks.forEach((sn) => {
      if (resTarget.stats[sn.id]) {
        const snItem = {
          id: sn.id,
          title: sn.title,
          shortName: sn.shortName,
          stats: resTarget.stats[sn.id],
          examples: resTarget.postExamples
            .filter((v) => v.social_network_id === sn.id)
            .map((v) => ({ sn: v.social_network_id, url: v.url }))
        }
        target.socialNetworks.push(snItem);
      }
    });
  }

  if (target.socialNetworks.length > 0 || target.description) {
    target.isShow = true;
    target.activeTab = target.socialNetworks?.length > 0 ? target.socialNetworks[0].id : 0
  }


  return target;
}

export const getVerticalAuditory = (audienceCityStructure, audienceInterestStructure, audienceSexAgeStructure) => {
  const _gender = [];
  const _city = [];
  const _interest = [];
  let auditory = {
    city: null,
    gender: null,
    interest: null,
  };

  let maxValue = 0;

  if (
    audienceCityStructure &&
    audienceCityStructure.length
  ) {
    maxValue = 0;
    const tmpArr = sortData(
      audienceCityStructure,
      "param_value"
    ).slice(0, 5);
    tmpArr.forEach((v) => (maxValue += v.param_value));

    if (maxValue) {
      tmpArr.forEach((v) => {
        _city.push({
          title: v.param_name,
          value: v.param_value,
          percent: (v.param_value / maxValue) * 100,
          decimal: v.param_value / maxValue,
        });
      });
    }

    if (_city.length) {
      auditory.city = _city;
    }
  }

  if (
    audienceInterestStructure &&
    audienceInterestStructure.length
  ) {
    maxValue = 0;
    const tmpArr = sortData(
      audienceInterestStructure,
      "param_value"
    ).slice(0, 5);
    tmpArr.forEach((v) => (maxValue += v.param_value));

    if (maxValue) {
      tmpArr.forEach((v) => {
        _interest.push({
          title: v.param_name,
          value: v.param_value,
          percent: (v.param_value / maxValue) * 100,
          decimal: v.param_value / maxValue,
        });
      });
    }

    if (_interest.length) {
      auditory.interest = _interest;
    }
  }

  if (
    audienceSexAgeStructure &&
    audienceSexAgeStructure.length
  ) {
    maxValue = 0;
    const tmpArr = audienceSexAgeStructure;
    tmpArr.forEach((v) => (maxValue += v.param_value));

    GenderAge.forEach((age) => {
      let arrGender = [];
      if (age.min) {
        if (age.max) {
          arrGender = tmpArr.filter(
            (v) => v.min_age === age.min && v.max_age === age.max
          );
        } else {
          arrGender = tmpArr.filter((v) => v.min_age === age.min);
        }
      } else {
        arrGender = tmpArr.filter((v) => v.max_age === age.max);
      }

      if (arrGender.length) {
        let t = {
          label: age.label,
        };
        arrGender.forEach((g) => {
          t[Gender[g.sex_id].name] = (g.param_value / maxValue) * 100;
        });
        _gender.push(t);
      }
    });

    if (_gender.length) {
      auditory.gender = _gender;
    }
  }
  return auditory;
}
export const getVerticalSources = (sources) => {
  return sources
    ? sources.map((v) => ({
      url: v.url,
      site: v.og_site_name,
      title: v.og_title,
      description: v.og_description,
      image: v.og_image,
    }))
    : [];
}
export const getVerticalDetailStatistics = () => {
  return;
}
export const getVerticalTopCreatives = (topCreatives) => {
  const creatives = [];

  if (topCreatives && topCreatives.length) {
    SocialNetworks.forEach((sn) => {
      const _creatives = topCreatives.filter(
        (v) => v.social_network_id === sn.id
      );
      if (_creatives.length) {
        creatives.push({
          ...sn,
          creatives: _creatives.slice(0, 5).map((_cr) => ({
            commentCount: _cr.comment_count,
            coverageCount: _cr.coverage,
            creativeCount: _cr.creative_count,
            image: _cr.image,
            likeCount: _cr.like_count,
            postCount: _cr.post_count,
            publicCount: _cr.public_count,
            shareCount: _cr.repost_count,
            er:
              ((_cr.like_count + _cr.comment_count + _cr.repost_count) /
                _cr.coverage) *
              100,
          })),
        });
      }
    });
  }
  return creatives;
}